.container {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	min-width: 45rem;
	max-height: 80vh;

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	padding: 10px;
}

.form {
	display: flex;
	flex-direction: column;
	gap: .875rem;
}

.rights__list {
	display: flex;
	flex-direction: column;
	gap: .75rem;
	margin-top: 0.5rem;
}

.error_message {
	color: var(--error-dark);
	text-align: center;
}

.buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
}