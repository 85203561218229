@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	position: sticky;
	top: var(--header-height);
	height: fit-content;

	& > hr {
		margin-bottom: 1.5rem;
		background-color: var(--color-secondary-1);
	}
}
