@use "src/shared/styles/variables/typography";

.form {
	min-width: 34.5rem;
	padding: 0 .5rem .5rem;
}

.header {
	text-align: center;
}

.fields {
	margin-bottom: 2rem;

	& > div:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.content {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	align-items: center;
}

.info {
	@include typography.font-body-1;

	text-align: center;

	& > div:first-of-type {
		margin-bottom: 1rem;
	}
}