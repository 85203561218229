@use "src/shared/styles/variables/typography";

.list {
	display: flex;
	flex-direction: column;
	gap: .5rem;

	& > p {
		@include typography.font-body-1;

		white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
		cursor: pointer;
	}
	& > p:hover {
		text-decoration: underline;
	}
}

.list:not(:last-child) {
	margin-bottom: 1.5rem;
}