@use "src/shared/styles/variables/typography";
@use "src/shared/styles/variables/mediaQuery";

.tabsList {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
}

.tab {
	@include typography.font-headline-3;

	@include mediaQuery.on-break-point(mobileDevices) {
		@include typography.font-headline-5;

		color: var(--color-secondary-3);
	}

	position: relative;

	padding-bottom: .3125rem;

	font-size: 22px;
	font-weight: 600;
	line-height: 1;
	color: var(--color-secondary-3);
	text-align: center;
	list-style-type: none;

	&:hover {
		cursor: pointer;
	}
}

.active {
	color: var(--color-primary-1);

	&::before {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 3px;

		background: var(--color-primary-1);
		border-radius: 8px;
	}
}

.underline {
	position: relative;
	z-index: 1;
	top: -.14rem;

	height: 0.0625rem;

	background-color: var(--color-secondary-1);
}
