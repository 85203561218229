@use 'src/shared/styles/variables/typography';

.bottom_text_wrapper {
	@include typography.font-text-description;

	display: flex;
	gap: 1rem;
	justify-content: space-between;
}

.bottom_text {
	place-self: flex-end;
	color: var(--color-secondary-3);
}

.bottom_text_max {
	color: var(--error-dark);
}

.error {
	display: inline-block;
	color: var(--error-dark);
}