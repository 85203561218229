@use "src/shared/styles/variables/typography";

.tableButtons {
  display: flex;
  gap: 1.5rem;
}

.project_title {
	@include typography.font-body-1;
}

.link_wrapper {
	@include typography.font-body-1;

	cursor: pointer;
	color: var(--color-primary-1);
}

