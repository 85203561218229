@use "src/shared/styles/variables/typography";
@use "src/shared/styles/variables/mediaQuery";

.container {
	position: sticky;
	z-index: var(--z-index-header);
	top: 0;
	right: 0;

	width: 100%;
	padding: 1.25rem 2rem;

	background-color: var(--color-bg-menu);
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	max-width: 60rem;
	width: 100%;

	margin: 0 auto;
}

.logoWrapper {
	& > .logo {
		margin-right: .75rem;
	}

  display: flex;
  align-items: center;

  text-decoration: none;
}

.logo {
  width: 1.75rem;
  height: 1.75rem;
}

.title {
  @include typography.headline-base;

  font-size: .875rem;
}



