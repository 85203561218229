@use 'src/shared/styles/layout/layout';

$close-icon-size: .875rem;

.container {
	@include layout.card;

	overflow: hidden;
	display: flex;
	flex-direction: column;

	width: 25rem;
	padding: 0;

	box-shadow: var(--shadow-active);
}

.content {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	padding: 1rem;
}

.message {
	display: flex;
	gap: .625rem;
	align-items: start;
}

.icon {
	&_success {
		fill: var(--success-dark);
	}

	&_error {
		fill: var(--error-dark);
	}
}

.close_icon {
	display: flex;
	align-items: center;

	width: $close-icon-size;
	min-width: $close-icon-size;
	height: $close-icon-size;
	min-height: $close-icon-size;

	fill: var(--color-secondary-2);

	&:hover {
		cursor: pointer;
		fill: var(--color-secondary-3)
	}
}

.bar {
	height: .1rem;

	&_info {
		background-color: var(--color-primary-1);
	}

	&_success {
		background-color: var(--success-dark);
	}

	&_error {
		background-color: var(--error-dark);
	}

	&_warning {
		background-color: var(--warning-dark);
	}
}

// * Animations
.anim_initialize {
	animation: slide-left .5s linear forwards;
}

.anim_close {
	animation: slide-right .5s linear forwards;
}

@keyframes slide-left {
	0% {
		margin-left: 120%;
	}

	100% {
		margin-left: 0;
	}
}

@keyframes slide-right {
	0% {
		margin-left: 0;
	}

	100% {
		margin-left: 120%;
	}
}
