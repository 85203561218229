.container {
	font-family: Gilroy, Arial, sans-serif;
}

.card {
	pointer-events: none;
	user-select: none;

	position: relative;

	width: 656px;
	height: 588px;
}

.title {
	margin-bottom: 16px;
	font-size: 22px;
}

.cardBody {
	position: relative;
	z-index: 2;

	display: flex;
	align-items: start;

	padding: 40px;
}

.leftCol {
	width: 190px;
	height: 100%;
	margin-right: 75px;
}

.rightCol {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;

	padding-top: 1.5rem;
	padding-right: 25px;
}

.imagePlaceholder {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 190px;
	height: 190px;
	margin-bottom: 30px;

	border-radius: 50%;
}

.name {
	margin-bottom: 10px;

	font-size: 22px;
	font-weight: 700;
	line-height: 25px;
	color: #414141;
	text-align: center;
}

.subtitle {
	margin-bottom: 10px;

	font-size: 14px;
	font-weight: 700;
	line-height: 25px;
	color: #414141;
	text-transform: uppercase;
}

.textInfo {
	font-size: 13px;
	line-height: 20px;
	color: #898989;

	& p {
		position: relative;

		font-family: Gilroy, Arial, sans-serif;
		font-size: 13px;
		font-weight: 500;
		line-height: 20px;
	}
}

.hobbies {
	margin-top: 30px;
}

.quote {
	position: relative;
	margin-top: 35px;
	line-height: 21px;
	color: #414141;

	svg {
		position: absolute;
		width: 13px;

		&:not(:first-child) {
			right: -20px;
			bottom: -20px;
			transform: rotate(180deg);
		}

		&:first-child {
			top: -12px;
			left: -27px;
		}
	}
}

.job {
	font-size: 14px;
	color: #898989;
	text-align: center;
	letter-spacing: 0.5px;
}

.camera {
	width: 25px;
	fill: #fff;
}

.greetings {
	margin-bottom: 68px;

	font-size: 17px;
	font-weight: 700;
	line-height: 28px;
	color: #fff;
	text-transform: uppercase;
}

.image {
	position: relative;
	left: 4px;

	width: 190px;
	height: 190px;
	margin-bottom: 20px;

	border-radius: 50%;
}

.logo {
	position: absolute;
	top: 13px;
	right: 25px;
	width: 85px;
}

.background {
	pointer-events: none;
	position: absolute;
	inset: 0;
	width: 100%;
}