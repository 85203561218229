@use 'src/shared/styles/variables/typography';

.filters {
	margin-bottom: 1rem;
}

.user_link {
	text-decoration: none;
}

.avatar {
	cursor: pointer;
}

.fullName {
	cursor: pointer;

	display: flex;
	gap: .5rem;
	align-items: center;

	color: inherit;
	text-decoration: none;
}

.positionName {
	@include typography.font-body-2;

	color: var(--color-secondary-3);
}