@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.wrapper {
	display: grid;
	gap: 2rem;
}

.filter {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 1.5rem;
}

.statistics {
	@include layout.card;

	&_title {
		margin-bottom: 1.5rem;
	}

	&_text {
		margin-bottom: 1rem;
		@include typography.font-headline-8;
	}

	&_text:last-child {
		margin-bottom: 0;
		@include typography.font-headline-8;
	}

	&_done {
		@include typography.font-headline-8;
	}

	&_info {
		display: grid;
		grid-template-columns: 1fr 4fr;
		column-gap: 249px;
	}

	&_columns {
		display: flex;
		column-gap: 2rem;
	}
}

.filter_users {
	display: grid;
	grid-template-columns: 1fr 5fr;
	column-gap: 1.5rem;
}

.text {
	margin-bottom: 1rem;
}
