@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/variables/mediaQuery';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	margin: 0 auto;

	width: 100%;
	max-width: 66.5rem;

	@include mediaQuery.on-break-point(desktopSmallOnly) {
		max-width: 53rem;
	}
}

.subtitle {
	@include typography.font-body-1;
}

.buttons {
	display: flex;
	gap: 1.5rem;
}
