@use 'src/shared/styles/variables/typography';

.caption {
	@include typography.font-body-2;

	color: var(--color-secondary-3);
}

.disabled {
	color: var(--color-black-3);
}
