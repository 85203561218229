.buttonRow {
	display: flex;
	justify-content: center;
	margin-bottom: 112px;

	& button:not(:last-child) {
		margin-right: 16px;
	}
}

.form {
	width: 100%;
	margin-right: 24px;
}

.newEmployee {
	display: flex;
	justify-content: space-between;
}

.inputBlock {
	display: inline-block;
	width: calc(50% - 12px);
	margin-bottom: 24px;

	&:nth-child(2n) {
		margin-right: 24px;
	}
}

.textAreaBlock {
	margin-bottom: 24px;
}

.avatarWrapper {
	margin-bottom: 32px;
}