@use "src/shared/styles/variables/typography";


.container {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: var(--color-bg-1);
	border-radius: 100%;
}

.size {
	&_large {
		width: 3.75rem;
		height: 3.75rem;
	}
	
	&_medium {
		width: 3rem;
		height: 3rem;
	}
	
	&_small {
		width: 2.5rem;
		height: 2.5rem;
	}
	
	&_extra-small {
		width: 2rem;
		height: 2rem;
	}
}

.name {
	@include typography.font-headline-4;

	color: var(--color-bg-blocks);

	&_small {
		@include typography.font-headline-6;

		color: var(--color-bg-blocks);
	}
}
