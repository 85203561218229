@use 'src/shared/styles/variables/typography';

// TODO: Full copy past of _input.module.scss. Except of $input-height: 6 * 23px; Organize variables.

$input-height-large: 6 * 1.4375rem;
$input-height-small: 3 * 1.4375rem;
$input-height-tiny: 2 * 1.4375rem;
$padding-vertical: .53125rem;
$padding-horizontal: 1rem;
$component-height-large: calc($input-height-large + $padding-vertical * 2 + 2px); // + 2px to compensate for 1px border.
$component-height-small: calc($input-height-small + $padding-vertical * 2 + 2px); // + 2px to compensate for 1px border.
$component-height-tiny: calc($input-height-tiny + $padding-vertical * 2 + 2px); // + 2px to compensate for 1px border.

@mixin border-hover {
  background: var(--color-bg-hover-50);
}

.container {
  width: 100%;
}

.active {
  & > .border {
    border: 1px solid var(--color-primary-1);
  }

  & > .placeholder {
    pointer-events: none;
    opacity: 0;
  }
}

.label {
  display: inline-block;
  height: fit-content;
  margin-bottom: .5rem;
  color: var(--color-secondary-3);
}

.icon {
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.inputWrapper {
  position: relative;
  top: 0;
  left: 0;

  width: 100%;
  margin-bottom: .3125rem;

  &_large {
    height: $component-height-large;
  }

  &_small {
    height: $component-height-small;
  }

  &_tiny {
    height: $component-height-tiny;
  }
}

.placeholder {
  @include typography.font-text-placeholder;

  position: absolute;
  z-index: 2;
  top: $padding-vertical;
  left: $padding-horizontal;

  color: var(--color-secondary-3);

  transition: .25s;
}

.input {
  @include typography.font-text-placeholder;

  resize: none;

  position: absolute;
  z-index: var(--z-index-input);
  top: $padding-vertical;
  left: 1rem;

  display: flex;
  align-items: center;

  width: calc(100% - 25px);

  background-color: transparent;
  border: none;
  outline: none;

  &:hover ~ .border {
    @include border-hover;
  }

  &:focus ~ .border,
  &:active ~ .border {
    border: 1px solid var(--color-primary-1);
  }

  &:focus ~ .placeholder,
  &:active ~ .placeholder {
    pointer-events: none;
    opacity: 0;
  }

  &_large {
    height: $input-height-large;
  }

  &_small {
    height: $input-height-small;
  }

  &_tiny {
    height: $input-height-tiny;
  }
}

.border {
  position: absolute;
  z-index: 2;
  inset: 0;

  width: inherit;
  padding: $padding-vertical $padding-horizontal;

  background-color: var(--color-bg-hover-25);
  border: 1px solid var(--color-secondary-1);
  border-radius: .75rem;

  &:hover {
    @include border-hover;
  }

  &_small {
    height: $component-height-small;
  }

  &_large {
    height: $component-height-large;
  }
}

// * Drop down only
.drop_down_container {
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  & .border {
    color: var(--color-black-3);
    background-color: var(--color-bg-disabled);
    border: 1px solid var(--color-black-3);
  }

  & .placeholder {
    color: var(--color-black-3);
  }

  &:hover .border {
    border: 1px solid var(--color-black-3);
  }

  &:focus .border,
  &:active .border {
    border: 1px solid var(--color-black-3);
  }

  &:focus .placeholder,
  &:active .placeholder {
    opacity: 100;
  }
}
