@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;
}

.header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.wrapper {
	padding-right: 1rem;
}

.label {
	@include typography.font-body-1;

	margin-bottom: .25rem;
}

.percent_line {
	width: 100%;
	height: .75rem;
	margin-bottom: .25rem;

	background-color: var(--color-bg-menu);
	border-radius: 10px;
}

.filled {
	height: .75rem;
	background-color: #3F98F5;
	border-radius: 10px;
}

.percent {
	@include typography.font-body-1;

	display: flex;
	justify-content: flex-end;
	color: var(--color-secondary-3);
}