.container {
  overflow-x: scroll;
  display: flex;
  align-items: flex-end;
}

.rows_wrapper {
  display: flex;
  flex: 1 0 6.25rem;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  margin-bottom: .25rem;
}

.columns_wrapper {
  display: flex;
  flex: 0 1 80%;
  justify-content: space-around;
  width: 100%;
}

.column_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: end;

  min-width: 7.5rem;
}