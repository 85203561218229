@use "src/shared/styles/variables/typography";

.container {
	width: 100%;
	max-width: 45rem;
}

.title {
	margin-bottom: 2rem;
}

.form_components {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin-bottom: 2rem;
}

.buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
}