@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	display: flex;
	gap: 1.5rem;
	align-items: center;
	justify-content: center;

	padding-top: .5rem;
	padding-bottom: .5rem;
}
