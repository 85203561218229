@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;

	max-width: 26.7rem;
}

.description {
	@include typography.font-body-1;

	text-align: center;
}

.social {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

.field {
	display: flex;
	gap: 0.75rem;
	align-items: center;
}

.buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
}