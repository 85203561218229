@use '../menu_mixins.module';

.container {
  @include menu_mixins.container;

  padding: 1rem 1.375rem;
}

.active {
  @include menu_mixins.active;
}


.name {
  width: 60vw;
}
