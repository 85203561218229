@use '../mixins.module';

.container {
  @include mixins.container;
}

.search {
  @include mixins.search;
}


