@use 'src/pages/offices/ui/_pages/RoomFormPage/form_steps/vars.module';
@use 'src/shared/styles/layout/layout';

.container {
	@include vars.container
}

.header {
	@include vars.header
}

.button_wrapper {
	@include vars.button_wrapper
}

.card {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.input_row {
	display: flex;
	gap: 2rem;
}
