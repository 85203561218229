@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 30rem;
}

.list {
	display: flex;
	flex-direction: column;
	gap: .25rem;
}
