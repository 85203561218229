@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 0;
}

.wrapper {
	display: flex;
	align-items: flex-start;
	gap: 1rem;
}

.label {
	@include typography.font-body-1;
}
