$card-padding: 1.5rem;

@mixin card {
	padding: $card-padding;
	background-color: var(--color-bg-blocks);
	border-radius: var(--card-border-radius);
	box-shadow: var(--shadow-default)
}

@mixin card-interactive {
	@include card;

	&:hover {
		box-shadow: var(--shadow-hover);
	}

	&:active {
		box-shadow: var(--shadow-active);
	}
}

@mixin card-large {
	@include card;

	padding: 2rem 3rem;
}

@mixin selected-card {
	padding: $card-padding;
	background-color: var(--color-bg-menu);
	border-radius: 10px; // TODO: A точно 10px а не .5rem.
}

@mixin flex($direction, $gap, $justify-content) {
	display: flex;
	flex-direction: $direction;
	gap: $gap;
	align-items: center;
	justify-content: $justify-content;
}

@mixin flex-start($direction, $gap, $justify-content) {
	display: flex;
	flex-direction: $direction;
	gap: $gap;
	align-items: flex-start;
	justify-content: $justify-content;
}
