@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.table {
	@include typography.font-body-2;

	display: flex;
	flex-direction: column;

	svg {
		fill: var(--color-secondary-2);
	}
	
	&_row {
		display: grid;
		gap: .75rem;
		grid-template-columns: 1.5rem 3fr 1fr 1.5rem;

		& > div {
			display: flex;
			align-items: center;
		}

		&:first-child {
			margin-bottom: .5rem;
		}

		&:last-child {
			margin-bottom: 0
		}
	}

	&_card {
		padding: .5rem 0;
	}

	&_header {
		color: var(--color-secondary-3);
	}

	&_cell:nth-child(3) {
		color: var(--color-secondary-3);
	}
}