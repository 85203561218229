.wrapper_edit {
  display: flex;
  flex: 0 1 32%;
  flex-flow: column wrap;
  gap: 1.5rem;

  & > button {
    flex: 0 1 9.25rem;
  };

  & > div {
    flex: 0 1 9.25rem;
  }
};