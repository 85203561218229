@use 'src/shared/styles/variables/typography';

.container {
  margin-right: -8px;
  margin-bottom: 8px;

  height: 32px;
}

.image {
  position: relative;

  overflow: hidden;
  display: inline-block;
  flex: 0 0 auto;

  width: 32px;
  height: 32px;

  border-radius: 50%;

  &:not(:first-child) {
    left: -7px;
  }

  &:nth-child(3n) {
    left: -14px;
  }

  &:nth-child(4n) {
    left: -21px;
  }
}

.imageRow {
  position: relative;

  display: flex;
}

.unvisibleImages {
  @include typography.font-body-3;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #fff;

  background-color: #B7C5E1;
}