@use "src/shared/styles/layout/layout";
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;

	&[aria-disabled='false']:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
	}

	&[aria-disabled='false']:active {
		box-shadow: var(--shadow-active);
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header_left {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.footer {
	display: flex;
	gap: 2rem;
	align-items: end;
	justify-content: space-between;
}

.time {
	@include typography.font-body-3;

	color: var(--color-primary-1);
}

.caption {
	@include typography.caption
}
