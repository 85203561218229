@use "src/shared/styles/variables/typography";

$icon-size: 1rem;

.title, .filters {
	margin-bottom: 1.5rem;
}

.filters {
	display: flex;
	gap: 1rem;

	& > div:first-child {
		width: 11.5rem;
	}
}

.user_link {
	text-decoration: none;
}

.avatar {
	cursor: pointer;
}

.fullName {
	cursor: pointer;

	display: flex;
	gap: .5rem;
	align-items: center;

	color: inherit;
	text-decoration: none;
}

.positionName {
	@include typography.font-body-2;

	color: var(--color-secondary-3);
}

.users {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}