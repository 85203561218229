@use '../mixins.module';

.container {
  @include mixins.container;
}

.menu {
  @include mixins.menu;
}

.content {
  @include mixins.content;
}
