.container {
	min-width: 24.75rem;
}

.header {
	text-align: center;
}

.description {
	margin-bottom: 2rem;
	text-align: center;
	white-space: pre-wrap;
}

.description:first-of-type {
	margin-bottom: 0.75rem;
}

.email {
	color: var(--color-primary-1)
}