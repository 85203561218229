.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.subgroup_name {
	color: var(--color-primary-1);
}

.inner {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}