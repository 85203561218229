.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  & .left {
    display: flex;
    align-items: center;
  }

  & .right {
    display: flex;

    & .button {
      margin-right: 16px;
    }
  }

  & h1 {
    margin-right: 12px;
  }

  & svg {
    fill: #3F98F5;
  }
}

.pageLayout {
  // display: flex;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.calendar {
  margin-right: 24px;
}