$card-padding: 1.5rem;
$close-icon-size: 1rem;

%button {
	position: fixed;
}

.layout__container {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.container {
	position: fixed;
	z-index: 3000;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: calc($card-padding + $close-icon-size) $card-padding $card-padding $card-padding;

	background-color: var(--color-bg-blocks);
	border-radius: 1rem;
	box-shadow: var(--shadow-modals);
}

.wrapper {
	max-height: 80vh;
	padding: .5rem;
}

.close_button {
	cursor: pointer;

	position: absolute;
	top: $close-icon-size;
	right: $close-icon-size;

	width: $close-icon-size;
	height: $close-icon-size;

	& svg {
		width: $close-icon-size;
		height: $close-icon-size;
		fill: var(--color-secondary-1);
	}

	&:hover {
		& > svg {
			fill: var(--color-primary-1);
		}
	}
}

.modal_container {
	overflow-y: hidden;
	height: 80vh;
}

.modal_image {
	overflow-y: hidden;

	width: 100%;
	height: 80vh;

	object-fit: cover;
	border-radius: .625rem;
}

.close {
	@extend %button;

	top: 1.5rem;
	right: 1.5rem;
}

.next {
	@extend %button;

	top: 49vh;
	right: 1.5rem;
}

.previous {
	@extend %button;

	top: 49vh;
	left: 1.5rem;
}

.download {
	@extend %button;

	top: 1.5rem;
	right: 6rem;
}
