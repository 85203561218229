@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
	}

	&:active {
		box-shadow: var(--shadow-active);
	}
}

.name {
	@include typography.font-headline-5;

	margin-bottom: 1.5rem;

	&_disabled {
		color: var(--color-black-3);
	}
}

.info {
	& > div:not(:last-child) {
		margin-bottom: .75rem;
	}
}

.total {
	@include typography.font-body-2;

	color: var(--color-secondary-2) 
}