@use 'src/shared/styles/variables/typography';

.all_files {
	margin-top: 1.5rem;
}

.file {
	display: grid;
	grid-template-columns: 3fr 1fr min-content;
	margin-bottom: 1.5rem;

	&_info {
		display: flex;
	}

	&_format {
		margin-right: 0.875rem;
	}

	&_name {
		@include typography.font-body-2;
	}

	&_size {
		color: var(--color-secondary-3);
	}

	& svg {
		fill: var(--color-secondary-3);
	}

	&_delete {
		cursor: pointer;
	}
}

.file:last-child {
	margin-bottom: 0;
}
