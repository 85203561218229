@use 'src/shared/styles/variables/typography';

@mixin tooltip {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	align-items: flex-start;
	padding: 1rem;
	color: #212121;
	background: #fdfeff;
	border-radius: 4px;
	box-shadow: 0 0 35px 3px rgb(70 79 126 / 50%);
}

.container {
	display: flex;
	align-items: flex-start;
	width: fit-content;
	width: 100%;
	height: 100%;
}

.pie_wrapper {
	width: 264px;
	height: 200px;
}

.legends_wrapper {
	display: flex;
	align-items: flex-start;
	width: fit-content;
}

.pie_tooltip {
	&_container {
		@include tooltip;
	}

	&_wrapper {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
	}

	&_text {
		@include typography.font-body-2;
	}
}

.bar_tooltip {
	&_container {
		@include tooltip;
	}

	&_text {
		@include typography.font-body-2;
	}

	&_option {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
}

.legend_tooltip {
	@include tooltip;

	position: absolute;
}

.dot {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
}
