@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	width: 100%;

	& > h3 {
		@include typography.font-headline-7;
	}
}

.all_filters {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
}

.first_row, .second_row {
	display: flex;
	gap: 1.5rem;
}

.second_row {
	margin-bottom: 1.5rem;
}

.third_row {
	margin-bottom: 4.6rem;
}

.buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
}