.container {
  width: 100%;
}

.label {
  display: inline-block;

  height: fit-content;
  margin-bottom: .5rem;
  margin-left: .25rem;

  color: var(--color-secondary-3);
}

.inputs_wrapper {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
