@use 'src/shared/styles/variables/typography';
@use './mixins.module';

// * Styles
.container {
	@include typography.font-headline-5;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	padding: 1rem 1.375rem;

	background: var(--color-bg-blocks);
	border-radius: .5rem;
	box-shadow: var(--shadow-default);

	& .icon {
		width: 1rem;
		min-width: 1rem;
		margin-right: .75rem;
		fill: var(--color-secondary-2);
	}

	&:hover {
		cursor: pointer;
		color: var(--color-black-1);
		background: var(--color-bg-1);
		box-shadow: var(--shadow-hover);

		& .icon {
			fill: var(--color-black-1)
		}
	}

	&:active, &.active {
		color: var(--color-black-1);
		background: var(--color-primary-1);
		box-shadow: var(--shadow-active);

		& .icon {
			fill: var(--color-black-1)
		}
	}

	&:focus-visible {
		outline: 2px solid var(--border-focus);
	}
}

.icon_name_wrapper {
	display: flex;
	align-items: center;
}

.name {
	width: 25rem;
	text-align: start;
}

.articles_wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
