@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.wrapper {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 1.5rem;
	margin-bottom: 2rem;
}

.heading {
	margin-bottom: 2rem;
}

.participants_heading {
	color: var(--color-secondary-2);
	margin-bottom: 1rem;
}

.button {
	margin-bottom: .5rem;
}

.user {
	display: flex;
	align-items: center;
	gap: 1rem;

	& > div:first-child {
		flex-shrink: 0;
	}
}

.name {
	font-weight: 600;
}

.position {
	@include typography.font-body-2;
	color: var(--color-secondary-2);
}

.modal > div > div > div {
	padding-top: 0;
}
