@use 'src/shared/styles/layout/layout';

.filters {
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 1.5rem;

	& > div:first-child {
		flex: 1;
	}
}

.tabs {
	margin-bottom: 1.5rem;
}

.multiAssign {
	margin-bottom: 1.5rem;
}

// Data
.files {
	display: flex;
	gap: .4rem;
	align-items: center;

	& > svg {
		fill: var(--color-secondary-2);
	}
}

.table {
	& td {
		vertical-align: top
	}
}