.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title {
  display: flex;
  justify-content: space-between;

	height: 2.55rem;

	margin-bottom: 0.25rem;
}

.edit_buttons {
  display: flex;
  gap: 1rem;
}