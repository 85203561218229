@use 'src/shared/styles/variables/typography';

.header {
	display: flex;
	gap: 1rem;

	margin-right: 4rem;
	margin-bottom: .5rem;
	margin-left: 1.5rem;

	& > span {
		@include typography.font-body-2;

		flex: 1 1 26rem;
		color: var(--color-secondary-3);
		text-transform: uppercase;
	}
}

.list {
	display: flex;
	flex-direction: column;
	gap: .75rem;
}
