@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/variables/mediaQuery';

.container {

	@include mediaQuery.on-break-point(desktopSmallOnly) {
		max-width: 53rem;
	}

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: center;

	width: 100%;
	max-width: 66.5rem;
	margin: 0 auto;
}

.wrapper_main {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

.section_count {
	@include typography.font-body-1;

	margin-bottom: .25rem;
	color: var(--color-primary-1);
}

.footer_buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
