@use 'src/shared/styles/variables/mediaQuery';

.container {
	@include mediaQuery.on-break-point(desktopSmallOnly) {
		max-width: 53rem;
	}

	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 100%;
	max-width: 66.5rem;
	margin: 0 auto;

	& > div {
		flex: 1;
	}
}
