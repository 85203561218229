@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/variables/mediaQuery';

.AuthPage {
	&_container {
		@include mediaQuery.on-break-point(mobileDevices) {
			background-image: none;
		}

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100vw;
		height: 100vh;

		background-image: url("../../../../shared/assets/svg/illustrations/loginBackground.svg");
		background-position-x: right;
		background-position-y: bottom;
		background-size: cover;
	}

	&_card {

		@include mediaQuery.on-break-point(mobileDevices) {
			width: 100%;
			border-radius: 0;
		}

		width: 29.75rem;
		padding: 4rem 2rem;
		background: var(--color-bg-default);
		border-radius: 16px;
	}


	&_header {
		@include mediaQuery.on-break-point(mobileDevices) {
			position: static;
		}

		position: relative;
	
		display: flex;
		align-items: center;
		justify-content: center;
	
		width: 100%;
	}
	
	&_navigation {

		@include mediaQuery.on-break-point(mobileDevices) {
			top: 8px;
		}

		position: absolute;
		left: 1rem;
		margin: 12px;
	}
	
	&_logo {
		width: 4.125rem;
		height: 4.125rem;
	}

	&_form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		width: 100%;
		margin-top: 2.5rem;

		& > div:not(:last-of-type) {
			margin-bottom: 1.5rem;
		}

		& > button:first-of-type {
			margin-top: 2.5rem;
		}
	}

	&_wrapper {
		position: relative;
		width: 100%;
	}

	&_btn_forgot {
		margin-top: 1rem;
		padding: 0;
	}

	&_error {
		@include typography.font-body-1;

		color: var(--error-dark);
		text-align: center;
	}

	&_recovery_info {
		@include typography.font-text-label;
	}
}
