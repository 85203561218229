.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}
