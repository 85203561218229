@use 'src/shared/styles/variables/typography';

.container {
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  & > div:first-child {
    flex-shrink: 0;
  }
}

.name {
  @include typography.font-headline-6;
}

.job {
  @include typography.font-text-description;
  color: var(--color-secondary-3);
}