@use 'src/shared/styles/variables/typography';

$icon-margin: 0.5rem;

@mixin container {
	@include typography.font-body-1;

	display: flex;
	align-items: center;
	justify-content: center;

	width: fit-content;
	padding: 0.5rem 1rem;

	&:has(svg) {
		padding: 0.5rem 0.63rem;
	}

	& > svg {
		@include icon;
	}
}

@mixin icon {
	display: flex;
	align-items: center;
	// height: .875rem;
}

.leftIcon {
	margin-right: $icon-margin;

	& > svg {
		@include icon;
	}
}

.loader {
	margin-left: 1rem;
}

.rightIcon {
	margin-left: $icon-margin;

	& > svg {
		@include icon;
	}
}
