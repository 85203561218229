.container {
	overflow: auto;

	min-width: 45rem;
	max-height: 80vh;

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	padding: 10px;

	& > h2 {
		margin-bottom: 2rem;
	}
}

.buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
	margin-top: 0.875rem;
}