@use "src/shared/styles/variables/typography";

.title {
	margin-bottom: 2rem;
	text-align: center;
}

.form_components {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.subtitle {

	@include typography.font-headline-5;

	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
	font-weight: 600;
}

.form_wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;

	& > div {
		flex: 1 1 45%;
	}
}

.helper_subtitle {
	@include typography.font-body-2;

	color: var(--default-input-text);
}

.error_subtitle {
	@include typography.font-body-2;

	color: var( --error-dark);
}

.form_buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
}