@use 'src/shared/styles/variables/typography';

.container {
	position: relative;

	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	width: 18rem;
}

.button {

	@include typography.font-headline-3;

	all: unset;
	cursor: pointer;
	color: var(--color-primary-1);
}
