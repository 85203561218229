@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;
	height: fit-content;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
