.cardTitle {
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 5.7vw;

  color: #414141;

  &__title {
    position: relative;
    z-index: 2;

    max-width: 75%;

    font-size: 1.9vw;
    line-height: 2.6vw;
    color: #414141;
    text-transform: uppercase;
    letter-spacing: 0.078vw;
  }

  &__img {
    position: relative;
    left: 2.8vw;
    width: 7.8vw;
    height: auto;
  }

  &__border {
    position: absolute;
    top: -19.7vw;
    left: -9.8vw;

    /* height: 300px; */
    width: 70.3vw;
  }
}


@media screen and (width >= 1920px) {
  .cardTitle {
    position: relative;

    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 110px;

    color: #414141;

    &__title {
      position: relative;
      z-index: 2;

      max-width: 75%;

      font-size: 37px;
      line-height: 50px;
      color: #414141;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    &__img {
      position: relative;
      left: 55px;
      width: 150px;
      height: auto;
    }

    &__border {
      position: absolute;
      top: -380px;
      left: -189px;

      /* height: 300px; */
      width: 1350px;
    }
  }
}