@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.actions {
	display: flex;
	gap: .75rem;
}

.info_wrapper:not(:last-child) {
	margin-bottom: 1.5rem;
}

.info_fields {
	display: grid;
	grid-template-columns: 200px 1fr;
	column-gap: 1.5rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	& > span {
		@include typography.font-body-1;

		white-space: pre-line;
	}

	& > span:first-child {
		color: var(--color-secondary-3);
	}
}

.info_tooltip {
	display: flex;
	gap: .25rem;
	align-items: center;

	& svg {
		width: .75rem;
		height: .75rem;
		fill: var(--color-secondary-2);
	}
}