@use 'src/shared/styles/variables/mediaQuery';

@mixin container {

  @include mediaQuery.on-break-point(desktopLargeOnly) {
    grid-template-columns: minmax(276px, 1fr) 3fr;
  }

  display: grid;
  grid-template-columns: minmax(276px, 1fr) 2.5fr;
  gap: 1.5rem;
}

@mixin menu {
  width: 100%;
}

@mixin content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}
