@use 'src/shared/styles/variables/typography';
@use 'src/pages/wiki/_common_components/tabs/menu_mixins.module';

.container {
	@include menu_mixins.container;

	padding-left: 1.5rem;
}

.active {
	@include menu_mixins.active;
}

.icon_name_wrapper {
	display: flex;
	align-items: center;
}

.arrow {
	fill: var(--color-secondary-1);
}

.articles_wrapper {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.name {
	width: calc(100% - 24px);
	overflow-wrap: break-word;
}
