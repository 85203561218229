@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/variables/mediaQuery';

.container {

	@include mediaQuery.on-break-point(mobileDevices) {
		z-index: 999;
		top: auto;
		bottom: calc(100% + 12px);
		left: 0;
		transform: translate(0, 0);

		width: 100%;
		max-width: 328px;
	}

	position: absolute;
	top: 50%;
	left: calc(100% + 16px);
	transform: translate(0, -50%);

	display: flex;
	flex-direction: column;
	row-gap: .25rem;

	width: max-content;
	padding: .75rem;

	background-color: var(--color-black-4);
	border-radius: 12px;
}

.rule {
	display: flex;
	column-gap: .25rem;
	align-items: center;

	& > span {
		@include typography.font-body-2;

		color: var(--color-black-1);
	}

	& > svg {
		width: .75rem;
		height: .75rem;
	}
}

.unchecked {
	padding: 2.5px;
	fill: var(--error-dark);

}

.checked {
	padding: 2.75px 1.5px;
	fill: var(--success-dark);
}