@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

// modal
.modal_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  min-width: 24.75rem;
}

.text {
  text-align: center;

  &_highlighted {
    color: var(--color-secondary-2);
  }
}

// content
.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card {
  @include layout.card;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.name {
  overflow-wrap: anywhere;
}

.edit_info {
  @include typography.font-body-3;

  margin-bottom: 1.5rem;
  color: var(--color-secondary-3);
}

.button_wrapper {
  display: flex;
  gap: .75rem;
  align-items: center;
}

.files {
  @include layout.card;

  &_headline {
    @include typography.font-headline-4;

    margin-bottom: 1rem;
  }
}

// ! Styles are hardcoded from public/tinyMCE.css
// ! ignore body margin
.article_text {
  // editors body ...
  font-family: Lato, Arial, serif;
  line-height: 1.4;
  color: #424242;

  // ! ignore margin;
  // ...
  overflow-wrap: anywhere;

  h1 {
    display: block;

    margin-block: 0.67em 0.67em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    display: block;

    margin-block: 0.83em 0.83em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    display: block;

    margin-block: 1em 1em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-size: 1.17em;
    font-weight: bold;
  }

  h4 {
    display: block;

    margin-block: 1.33em 1.33em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-weight: bold;
  }

  h5 {
    display: block;

    margin-block: 1.67em 1.67em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-size: 0.83em;
    font-weight: bold;
  }

  h6 {
    display: block;

    margin-block: 2.33em 2.33em;
    margin-inline: 0 0;

    font-family: Montserrat, sans-serif;
    font-size: 0.67em;
    font-weight: bold;
  }

  p {
    display: block;
    // margin-block: 1em 1em;
    // margin-inline: 0 0;
  }

  table {
    border-collapse: collapse;
  }

  /* Apply a default padding if legacy cellpadding attribute is missing */
  table:not([cellpadding]) th,
  table:not([cellpadding]) td {
    padding: 0.4rem;
  }

  /* Set default table styles if a table has a positive border attribute
     and no inline css */
  table[border]:not([border="0"], [style*="border-width"]) th,
  table[border]:not([border="0"], [style*="border-width"]) td {
    border-width: 1px;
  }

  /* Set default table styles if a table has a positive border attribute
     and no inline css */
  table[border]:not([border="0"], [style*="border-style"]) th,
  table[border]:not([border="0"], [style*="border-style"]) td {
    border-style: solid;
  }

  /* Set default table styles if a table has a positive border attribute
     and no inline css */
  table[border]:not([border="0"], [style*="border-color"]) th,
  table[border]:not([border="0"], [style*="border-color"]) td {
    border-color: #ccc;
  }

  figure {
    display: table;
    margin: 1rem auto;
  }

  figure figcaption {
    display: block;
    margin-top: 0.25rem;
    color: #999;
    text-align: center;
  }

  hr {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 0;
  }

  code {
    padding: 0.1rem 0.2rem;
    background-color: #e8e8e8;
    border-radius: 3px;
  }

  blockquote {
    margin-left: 1.5rem;
    padding-left: 1rem;
    border-left: 2px solid #ccc;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ol {
    margin: revert;
    padding: revert;
  }

  ul {
    margin: revert;
    padding: revert;
  }
}
