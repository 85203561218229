@import "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Avatar {
	&_container {
		position: relative;

		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		margin-bottom: 2rem;

		background-color: var(--color-bg-1);
		border-radius: 100%;
	}

	&_size {
		width: 10.25rem;
		height: 10.25rem;
	}

	&_name {
		font-size: 3.125rem;
		font-weight: 600;
		color: var(--color-bg-blocks);
	}
}

.buttons {
	display: flex;
	gap: 1.5rem;
	justify-content: center;
}

.btn_wrapper {
	position: relative;
}

.label {
	cursor: pointer;

	position: absolute;
	z-index: 10;
	top: 0;

	display: block;

	width: 100%;
	height: 100%;

	border-radius: 1rem;
}