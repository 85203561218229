@mixin gallery-item {
  display: flex;
  align-items: center;
  justify-content: center;
  
  box-shadow: 0 0 .4375rem 1px rgb(5 35 181 / 8%);
}

@mixin item-full {
  width: 18.1875rem;
  height: 12.5rem;
  border-radius: .625rem;
}

@mixin item-small {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: .25rem;
}