@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.comments_info {
	display: grid;
	grid-template-columns: auto 2fr auto;
	gap: 1.5rem;
	margin: 2rem 0;
	align-items: flex-start;

	&_left {
		display: flex;
		flex-direction: column;
	}
}

.comments_info:last-child {
	margin-bottom: 0rem;
}

.date_time {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;

	&_answer {
		cursor: pointer;
	}
}

.time {
	@include typography.font-body-3;
	color: var(--color-secondary-3);
}
