@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	width: 100%;
}

.card {
	@include layout.card;
}

.header_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5625rem;
}

.header_container_small {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: flex-start;
}

.name {
	@include typography.font-headline-1;

	color: var(--color-black-4);
	text-align: center;
}

.description {
	@include typography.font-body-1;

	margin-bottom: 2.25rem;
	color: var(--color-black-4);
}

.info_container {
	display: flex;
	flex-wrap: wrap;

	:nth-last-child(1),
	:nth-last-child(2) {
		margin-bottom: 0;
	}
}
