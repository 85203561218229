.container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: 50rem;
}

.row {
	display: flex;
	gap: 1.5rem;

	& > div:first-child {
		flex: 1 0 70%;
	}
}

.buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
}