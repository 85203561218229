@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;
	display: flex;
	flex-direction: column;

	& > div:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.title {
	margin-bottom: 2rem;
}

.form_item_select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
}

.form_item_select_data_time {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
}
