.colorSection {
  width: 95%;
  margin-bottom: 0.7vw;
  padding: 1vw 2vw;

  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;

  &__text {
    font-size: 1.5vw;
    font-weight: 700;
    line-height: 1.8vw;
    color: #414141;
    text-transform: uppercase;
  }
}

@media screen and (width >= 1920px) {
  .colorSection {
    width: 95%;
    margin-bottom: 15px;
    padding: 20px 40px;

    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;

    &__text {
      font-size: 29px;
      font-weight: 700;
      line-height: 35px;
      color: #414141;
      text-transform: uppercase;
    }
  }
}