@use 'src/shared/styles/variables/typography';

.user_checkbox {
	display: flex;
	gap: 1.5rem;
	align-items: center;
}

.fullName {
	@include typography.font-headline-5;
}

.positionName {
	@include typography.font-body-2;

	color: var(--color-secondary-2);
}

.selected_block {
	display: flex;
	justify-content: space-between;
}

.selected_switch {
	display: flex;
	gap: 1rem;
}

.table {
	overflow-y: auto;

	margin-right: -1rem;
	margin-left: -1rem;
	padding-right: 1rem;
	padding-left: 1rem;
}
