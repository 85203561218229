@use 'src/shared/styles/variables/typography';

$arrow-size: 1rem;

.container {
	position: relative;

	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
}

.button {
	cursor: pointer;

	& > svg {
		width: $arrow-size;
		min-width: $arrow-size;
		height: $arrow-size;
		min-height: $arrow-size;
		margin-left: 0.25rem;
	
		fill: var(--color-primary-1);
	}

	&_h1 {
		@include typography.font-headline-1;

		color: var(--color-primary-1);
	}

	&_h2 {
		@include typography.font-headline-2;

		color: var(--color-primary-1);
	}

	&_h3 {
		@include typography.font-headline-3;

		color: var(--color-primary-1);
	}

	&_h4 {
		@include typography.font-headline-4;

		color: var(--color-primary-1);
	}

	&_h5 {
		@include typography.font-headline-5;

		color: var(--color-primary-1);
	}

	&_h6 {
		@include typography.font-headline-6;

		color: var(--color-primary-1);
	}
}
