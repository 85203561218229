@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.claim {
	@include layout.card;
	display: grid;
	gap: 1.5rem;

	&_info {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	&_title {
		@include typography.font-headline-8;
	}
}
