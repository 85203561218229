@use "src/shared/styles/variables/typography";

$icon-size: 1rem;

.icon_wrapper {
	position: relative;	

	display: flex;
	gap: 0.625rem;
	align-items: center;
	justify-content: end;

	& > svg {
		width: $icon-size;
		min-width: $icon-size;
		height: $icon-size;
		min-height: $icon-size;

		fill: var(--color-secondary-2);
	}
}

.user {
	&_name {
		@include typography.font-field-label;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&_position {
		@include typography.font-text-description;

		color: var(--color-secondary-3);
	}
}
.tooltip {
	@include typography.font-body-3;

	position: absolute;
	right: 3rem;
	bottom: 0;

	width: max-content;
	padding: 16px 24px;

	color: var(--color-black-1);

	background: var(--color-secondary-3);
	border-radius: 12px 12px 2px;
}

.project_title {
	@include typography.font-body-1;
}

.link_wrapper {
	@include typography.font-body-1;

	cursor: pointer;
	color: var(--color-primary-1);
}

.modal_content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;

	& > div {
		width: 30rem;
	}
}

.users_hours {
	color: var(--color-secondary-3);
}