@use "src/shared/styles/layout/layout";

.container {
	@include layout.card;

	grid-column: 1;
	grid-row: 1;
}

.lineTabs {
	margin-bottom: 2rem;
}
