@use 'src/shared/styles/variables/mediaQuery';

.container {

	@include mediaQuery.on-break-point(desktopSmallOnly) {
		grid-template-columns: 1fr 1fr;
	}

	@include mediaQuery.on-break-point(mobileDevices) {
		grid-template-columns: 1fr;
	}

	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: 1fr 1fr 1fr;
}

.container_template {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: 1fr 1fr;

	@media (width <= 1279px) {
		grid-template-columns: 1fr;
	}
}
