@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";
@use "src/shared/styles/variables/mediaQuery";

.container {
	@include layout.card;

	@include mediaQuery.on-break-point(mobileDevices) {
		padding: .75rem;
	}

	width: 100%;
	padding: 2rem 1.5rem;
}

.question_error {
	border: 1px solid var(--error-dark);
}

.question {
	display: flex;
	gap: 1.5rem;
	align-items: start;
	justify-content: space-between;

	height: auto;

	&_content {
		@include typography.font-text-placeholder;
	}

	& h3 {
		color: var(--error-dark);
	}
}

.question_wrapper {

	@include mediaQuery.on-break-point(desktopLargeOnly) {
		justify-content: center;
	}

	@include mediaQuery.on-break-point(desktopMediumOnly) {
		justify-content: center;
	}

	display: flex;
	justify-content: flex-start;

	& > div {
		width: 100%;
	}
}

.question_image {
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;

	& > img {
		max-height: 20rem;
	}
}
