.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.loading {
  display: flex;
  justify-content: center;
}

.subtitle {
  color: var(--color-secondary-3);
}