@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	gap: 1rem;
	align-items: flex-start;
}

.first_wrapper {
	display: flex;
	flex: 1 1 50%;
	gap: 1rem;
	align-items: center;

	& .icon {
		margin-right: 0.375rem;
		fill: var(--color-primary-1);
	}
}

.second_wrapper {
	display: flex;
	gap: 4rem;
	align-items: flex-start;
	width: 100%;
}


.personal_photo {
	flex: 1 1 30%;

	width: 100%;
	max-width: 354px;
	height: 290px;

	background: linear-gradient(180deg, rgb(129 240 255 / 50%) 0%, rgb(66 131 255 / 50%) 100%);
	border-radius: 4px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.about {
	display: flex;
	flex: 1 1 60%;
	flex-direction: column;
}

.fullname {
	margin-bottom: 0.25rem;
}

.position {

	@include typography.font-text-label;

	margin-bottom: 1rem;
	color: #6C7898;
}

.description {
	@include typography.font-body-1;
}
