@use "mediaQuery";

// * local mixins
@mixin headline-base {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: var(--color-black-4);
  overflow-wrap: anywhere;
}

@mixin body-base {
  font-family: Lato, Arial, serif;
  font-weight: 400;
  color: var(--color-black-4);
}

// * Headlines
@mixin font-headline-1 {
  @include headline-base;

  font-size: 2.125rem;
  line-height: 2.4438rem;
}

@mixin font-headline-2 {
  @include headline-base;

  font-size: 1.7rem;
  line-height: 2.1rem;
}

@mixin font-headline-3 {
  @include headline-base;

  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.8563rem;
}

@mixin font-headline-4 {
  @include headline-base;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5188rem;
}

@mixin font-headline-5 {
  @include headline-base;

  font-size: 1rem;
  line-height: 1.375rem;
}

@mixin font-headline-6 {
  @include headline-base;

  font-size: .875rem;
  line-height: 145%;
}

// ! Кастомные...
@mixin font-headline-7 {
  @include headline-base;

  font-size: 1.375rem;
  line-height: 30px;
}

@mixin font-headline-8 {
  @include headline-base;

  font-size: 1rem;
	font-weight: 600;
  line-height: 22px;
}
// ! ...Кастомные

// * Body
@mixin font-body-1 { // - Large-medium buttons
  @include body-base;

  font-size: 1rem;
  line-height: 1.45rem;
}

@mixin font-body-2 {
  @include body-base;

  font-size: 0.875rem;
  line-height: 1.225rem;
}

@mixin font-body-3 {
  @include body-base;

  font-size: .75rem;
  line-height: 0.975rem;
}

@mixin font-body-4 { // - Small buttons
  @include body-base;

  font-size: 0.875rem;
  line-height: 1.27rem;
}

@mixin font-text-placeholder { // tooltip label
  @include body-base;

  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}

@mixin font-text-label { // tooltip label
  @include body-base;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.45rem;
}

@mixin font-field-label { // field label
  @include body-base;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

@mixin font-text-description {
  @include body-base;

  font-size: .75rem;
  line-height: .9rem;
}

@mixin caption {
  @include font-body-4;

  color: var(--color-secondary-3)
}
