@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.header {
	display: flex;
	justify-content: space-between;
	gap: 1.5rem;

	&__title {
		font-weight: 700;
	}

	& > div {
		display: flex;
		align-items: center;
	}

	& > div:first-child {
    height: fit-content;
		gap: .5rem;
	}

	& > div:last-child {
		gap: .75rem;
	}

	& svg {
		fill: var(--color-secondary-2);
	}
}