.container {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-grayscale-3);
  border: none;
  border-radius: 1rem;

  fill: var(--color-black-3);

  &:hover {
    cursor: pointer;
    fill: var(--color-black-2);
  }

  &:focus {
    fill: var(--color-black-2);
  }

  &:active {
    fill: var(--color-bg-blocks);
  }
}
