@use "src/shared/styles/variables/mediaQuery";
@use "src/shared/styles/variables/typography";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%; // controlled by grid
  height: 100vh;

  background-color: var(--color-bg-menu);

  &_adaptive {
    @media (max-width: mediaQuery.$screen-tablet-max) {
      display: none;
    }
  }
}

.logoWrapper {
  & > .logo {
    margin-right: .625rem;
  }

  display: flex;
  align-items: center;

  margin-top: 1.625rem;
  margin-bottom: 2.625rem;

  text-decoration: none;

  &_adaptive {
    @include mediaQuery.on-break-point(bellowDesktopLarge) {
      & > .logo {
        margin-right: 0;
      }
    }
  }
}

.logo {
  width: 1.75rem;
  height: 1.75rem;
}

.close_btn {
  position: absolute;
  top: 28px;
  right: 12px;
}

.title {
  @include typography.headline-base;

  font-size: .875rem;

  &_adaptive {
    @include mediaQuery.on-break-point(bellowDesktopLarge) {
      display: none;
    }  
  }
}

.menuOptions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: end;

  width: 100%;
}
