@use "src/shared/styles/variables/typography";

.container:not(:last-child) {
	margin-bottom: 1.5rem;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.fields {
	display: grid;
	grid-template-columns: 200px 1fr auto;
	grid-template-rows: auto auto;
	column-gap: 1.5rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	& > span {
		@include typography.font-body-1;
	}

	& > span:nth-child(2) {
		font-weight: 500;
	}
}

.actions {
	display: flex;
	gap: .5rem;
	align-items: center;
}