@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	gap: .75rem;
}

.content {
	display: flex;
	flex-direction: column;
	gap: .25rem;
}

.wrapper {
	display: flex;
	gap: .75rem;
	justify-content: space-between;
}

.label {
	display: flex;
	column-gap: .25rem;
	align-items: center;

	& > span {
		@include typography.font-body-1;
	
		color: var(--color-secondary-3);
	}

	& > svg {
		width: .75rem;
		height: 1rem;
		fill: var(--color-secondary-2);
	}
}

.actions {
	display: flex;
	gap: .5rem;
}

.warning {
	@include typography.font-text-description;

	color: var(--error-dark);
}

