.claim_info {
	display: grid;
	grid-template-columns: 1fr 1fr 0.5fr;
	gap: 1.5rem;
	margin-bottom: 1.5rem;
}

.claim_info:last-child {
	margin-bottom: 0rem;
}
