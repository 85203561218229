// ! Вот это вот влияет на всю систему. Не использовать * без привязки к классу!!!
// * {
// 	font-family: Montserrat, sans-serif;
// }

.stepContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1.5rem;

	&__wrapper {
		display: flex;
		justify-content: flex-start;
		width: 34rem;
	}

	& button {
		margin: 0 2rem;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	width: 100%;
	padding: 1.5rem;

	background-color: var(--color-bg-blocks);
	border-radius: 10px;
	box-shadow: var(--shadow-default);
}