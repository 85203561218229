@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.wrapper {
	@include layout.card;
	padding-bottom: 44px;
}

.title {
	margin-bottom: 1rem;
}

.comments {
	display: grid;
	align-items: center;
	grid-template-columns: auto 1fr auto;
	column-gap: 1rem;

	&_send {
		cursor: pointer;
	}
}
