@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.icon {
	& > svg {
		fill: var(--color-secondary-2);
	}
}