.container {
	container: gallery / inline-size;
}

.inner {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1rem;

	@container gallery (max-width: 50rem) {
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	}
}