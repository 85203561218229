@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/variables/mediaQuery';

.email_info {
	@include typography.font-text-label;

	margin-top: 5rem;
	text-align: center;
}

.recovery_info {
	@include typography.font-text-label;
}

.form {
	@include mediaQuery.on-break-point(mobileDevices) {
		height: 100%;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	margin-top: 2.5rem;

	& > div {
		margin-bottom: 2.5rem;
	}
}
