@mixin color($color) {
  & > span::before {
    background-color: $color;
    border: 1px solid $color;
  }

  & > input:not(:disabled, :checked) + span:hover::before {
    border-color: $color;
  }

  & > input:focus + span::before {
    border-color: $color;
  }

  & > input:focus:not(:checked) + span::before {
    border-color: $color;
  }

  & > input:checked + span::before {
    background-color: $color;
    border-color: $color;
  }
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  & > div {
    margin-left: .5rem;
  }

  & > input {
    position: relative;
    z-index: -1;
    width: 0;
    opacity: 0;
  }

  & > span {
    user-select: none;
    display: inline-flex;
    align-items: center;
    width: inherit;
  }

  & > span::before {
    content: '';

    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;

    width: 3rem;
    height: 3rem;

    background-color: var(--color-primary-1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border: 1px solid var(--color-primary-1);
    border-radius: 100%;
  }

  & > input:not(:disabled, :checked) + span:hover::before {
    border-color: var(--color-primary-1);
  }

  & > input:focus + span::before {
    border-color: var(--color-primary-1);
  }

  & > input:focus:not(:checked) + span::before {
    border-color: var(--color-primary-1);
  }

  & > input:checked + span::before {
    background-color: var(--color-primary-1);
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 0.783849C18.0976 1.16231 18.0976 1.77593 17.7071 2.15439L6 13.5L0.292893 7.96912C-0.0976311 7.59066 -0.0976311 6.97704 0.292893 6.59858C0.683417 6.22011 1.31658 6.22011 1.70711 6.59858L6 10.7589L16.2929 0.783849C16.6834 0.405384 17.3166 0.405384 17.7071 0.783849Z'/%3E%3C/svg%3E%0A");
    background-size: 40% 40%;
    border-color: var(--color-primary-1);;

    & > svg {
      fill: #3dcc4a;
    }
  }

  &_0 {
    @include color(var(--color-1))
  }

  &_1 {
    @include color(var(--color-2))
  }

  &_2 {
    @include color(var(--color-3))
  }

  &_3 {
    @include color(var(--color-4))
  }

  &_4 {
    @include color(var(--color-5))
  }

  &_5 {
    @include color(var(--color-6))
  }

  &_6 {
    @include color(var(--color-7))
  }

  &_7 {
    @include color(var(--color-8))
  }

  &_8 {
    @include color(var(--color-9))
  }

  &_9 {
    @include color(var(--color-10))
  }

  &_10 {
    @include color(var(--color-11))
  }

  &_11 {
    @include color(var(--color-12))
  }
}
