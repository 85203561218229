.container {
  display: flex;
  gap: 1.5rem;
  align-items: stretch;
  justify-content: stretch;
}

.menu {
  flex: 1 1 18.9375rem;
}

.content {
  flex: 1 0 70%;
  display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
