.offerCard {
	transform-origin: 0 0;

	overflow: hidden;

	min-width: 6.25vw;
	padding: 6vw 0;

	background: white;

	& * {
		font-family: Gilroy, Arial, sans-serif;
	}

	&__border {
		position: absolute;
		z-index: 1;
		top: -6vw;
		left: 0;

		width: 60vw;
		height: 28vw;
	}
}

.headerImage {
	position: absolute;
	z-index: 2;
	top: -6vw;
	right: 0;

	overflow: hidden;
}

.blue {
	margin-bottom: 1rem;

	font-size: 1.8vw;
	line-height: 2.5vw;
	color: #279EE1;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.hrTeam {
	font-size: 1.3vw;
	font-weight: 700;
	text-transform: uppercase;
}

.image {
	width: 44vw;
	height: auto;
}

.container {
	display: flex;
	justify-content: space-between;
}

.buttons {
	position: sticky;
	top: 137px;

	width: 100%;
	max-width: 20vw;
	max-height: 15vw;
	margin-left: 1.2vw;

	& button {
		font-size: 1vw;
	}

	& button:not(:last-child){
		margin-bottom: 0.8vw;
	}

	@media screen and (width <= 1023px) {
		top: 88px;
	}
}

.invitation {
	display: flex;
	gap: 2rem;
	justify-content: space-between;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 2vw;
	}

	&__title {
		width: 20vw;

		font-size: 1vw;
		font-weight: bold;
		line-height: 1.8vw;
		color: #414141;
		text-transform: uppercase;
	}

	&__description {
		width: 100%;
		font-size: 1.3vw;
		line-height: 1.8vw;
		color: #6B6B6B;
	}
}

.endOfTrialHeader {
	margin-top: 3vw;
	margin-bottom: 2vw;

	font-size: 1.2vw;
	color: #414141;
	text-transform: uppercase;
}

.section {
	overflow: hidden;
	padding: 4.1vw 6.6vw 1.2vw;

	&_first {
		padding: 0 6.6vw 5.2vw;
	}
}

.header {
	position: relative;
	padding: 0 6.6vw 6.6vw;


	&__text {
		position: relative;
		z-index: 2;
	}
}

.invitationTitle {
	margin-bottom: 2.6vw;

	font-size: 1.7vw;
	font-weight: 600;
	line-height: 2.3vw;
	color: #414141;
	letter-spacing: 0;
}

.logo {
	width: 10vw;
	height: auto;
	margin-bottom: 4.1vw;
}

.greetings {
	font-size: 2.5vw;
	font-weight: bold;
	font-stretch: expanded;
	color: #414141;
	text-transform: uppercase;

	&_name {
		margin-bottom: 2vw;
		color: var(--color-primary-1);
	}
}

@media screen and (width >= 1920px) {
	.offerCard {
		overflow: hidden;
		min-width: 1200px;
		padding: 127px 0;
		background: white;

		&__border {
			position: absolute;
			z-index: 1;
			top: -127px;
			left: 0;

			width: 1150px;
			height: 550px;
		}
	}

	.headerImage {
		position: absolute;
		z-index: 2;
		top: -127px;
		right: 0;

		overflow: hidden;
	}

	.blue {
		margin-bottom: 20px;

		font-size: 36px;
		line-height: 50px;
		color: #279EE1;
		letter-spacing: 1px;
	}

	.hrTeam {
		font-size: 25px;
		font-weight: 700;
		text-transform: uppercase;
	}

	.image {
		width: 855px;
		height: auto;
	}

	.container {
		display: flex;
		justify-content: space-between;
	}

	.buttons {
		position: sticky;
		top: 137px;

		width: 100%;
		max-width: 384px;
		max-height: 300px;
		margin-left: 24px;

		& button:not(:last-child){
			margin-bottom: 16px;
		}

		& button {
			font-size: inherit;
		}
	}

	.invitation {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		&__title {
			width: 15.5vw;

			font-size: 21px;
			font-weight: bold;
			line-height: 35px;
			color: #414141;
			text-transform: uppercase;
		}

		&__description {
			width: 100%;
			font-size: 25px;
			line-height: 35px;
			color: #6B6B6B;
		}
	}

	.endOfTrialHeader {
		margin-top: 60px;
		margin-bottom: 40px;

		font-size: 24px;
		color: #414141;
		text-transform: uppercase;
	}

	.section {
		overflow: hidden;
		padding: 80px 127px 25px;

		&_first {
			padding: 0 127px 100px;
		}
	}

	.header {
		position: relative;
		padding: 0 127px 127px;


		&__text {
			position: relative;
			z-index: 2;
		}
	}

	.invitationTitle {
		margin-bottom: 50px;

		font-size: 33px;
		font-weight: 600;
		line-height: 45px;
		color: #414141;
		letter-spacing: 0;
	}

	.logo {
		width: 200px;
		height: auto;
		margin-bottom: 80px;
	}

	.greetings {
		font-size: 48px;
		font-weight: bold;
		font-stretch: expanded;
		color: #414141;
		text-transform: uppercase;

		&_name {
			margin-bottom: 40px;
			color: var(--color-primary-1);
		}
	}
}

.contacts {
	padding-top: 4vw;
	padding-bottom: 0;

	&__heading {
		margin-bottom: 0.2vw;

		font-size: 1.4vw;
		font-weight: 700;
		line-height: 1.1vw;
		color: #414141;
		text-transform: uppercase;
	}

	&__paragraph {
		font-size: 1.6vw;
		line-height: 2vw;
		color: #6B6B6B;
	}

	@media screen and (width >= 1920px) {
		padding-top: 52px;

		&__heading {
			font-size: 29px;
		}

		&__paragraph {
			font-size: 30px;
			line-height: 40px;
		}
	}
}