.container {
  display: inline;
}

.tooltip {
  width: fit-content;
  padding: 4px 8px;

  font-size: 90%;
  color: white;

  background-color: #444;
  border-radius: 4px;
}
