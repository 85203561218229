@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

$padding-gap: layout.$card-padding;

@mixin element-wrapper {
	display: flex;
	flex: 0 0 calc(9rem - $padding-gap);
	align-items: center;
}

.container {
	@include layout.card;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.clickable {
	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
	}

	&:active {
		box-shadow: var(--shadow-active);
	}
}

.info_wrapper {
	@include typography.font-body-1;

	display: flex;
	gap: 1rem;
	align-items: start;
	justify-content: space-between;

	width: 100%;

	& > div, span {
		flex: 1 1 26rem;
		overflow-wrap: anywhere;
	}
}

.name {
	@include typography.font-headline-8;
}

.menu {
	display: flex;
	flex: 0 0 calc(4rem - $padding-gap);
	align-items: center;
}
