// TODO: Mostly duplicate of FullBookingTable.
@mixin cell {
  overflow: hidden;

  height: 40px;
  padding: .5rem;

  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 2px;
}

@mixin row {
  position: relative;
  display: flex;
  gap: 2px;

	& svg {
		width: .75rem;
		height: .75rem;
	}
}

.container {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 2px;

  background-color: white; // !HARDCODE
}

.row {
  @include row;
}

.first_row {
  @include row;

  color: var(--color-secondary-3);

  // Disable cell styles.
  & > div {
    background-color: white; // !HARDCODE
    &:first-child {
      color: var(--color-primary-1);
    }

    &:hover {
      cursor: default;
      background-color: white; // !HARDCODE
    }
  }
}

.first_cell {
  @include cell;

  position: sticky;
  z-index: 3;
  top: 0;
  left: 0;

  flex-shrink: 0;

  // width - inline.

  color: var(--color-black-1);

  background-color: var(--color-secondary-1);

  &:hover {
    cursor: pointer;
    background-color: var(--color-bg-1);
  }

  &:active, &_active {
    background-color: var(--color-primary-1);
  }
}

.innerCell {
  @include cell;

  flex: 1 0 70px;
  background-color: var(--color-bg-disabled);

  &_active {
    background-color: var(--color-bg-hover-75);

    &:hover {
      cursor: pointer;
      background-color: var(--color-bg-1);
    }
  }
}

.selected_interval {
  @include cell;

  position: absolute;

  // left - inline.
  z-index: 2;
  top: 0;

  display: none; // turns to block - inline.

  // width - inline.

  color: var(--color-bg-default);

  background-color: var(--color-secondary-2);

  //outline: 2px solid white; // !HARDCODE - color

  & svg {
    position: relative;
    top: .05rem;
    margin-right: .25rem;
    fill: white;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-secondary-3);
  }

  &:active {
    background-color: var(--color-primary-1);
  }
}

.active_user {
  background-color: var(--color-primary-1);

  &:hover {
    background-color: var(--color-primary-2);
  }

  &:active {
    background-color: var(--color-primary-3);
  }
}

.lock {
  background-color: var(--color-black-3);

  &:hover {
    background-color: var(--color-black-3);
  }

  &:active {
    background-color: var(--color-black-3);
  }
}
