@use 'src/shared/styles/variables/typography';

.title {
	margin-bottom: 2rem;
}

.filter_block {
	margin-bottom: 2rem;
}

.selected_block {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.selected_text {
	color: var(--color-secondary-2);
}

.selected_switch {
	display: flex;
	gap: 1rem;
}

.create_user_button {
	margin-bottom: 1rem;
}

.header {
	display: flex;
	margin-bottom: .5rem;
	margin-left: 9rem;

	& > span {
		@include typography.font-body-3;

		flex: 1 1 26rem;
		color: var(--color-secondary-2);
		text-transform: uppercase;
	}
}

.users_list {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.user_checkbox {
	display: flex;
}

.list {
	overflow-y: auto;

	height: 40vh;
	margin-right: -1rem;
	margin-bottom: 1rem;
	margin-left: -1rem;
	padding-right: 1rem;
	padding-left: 1rem;
}

.about {
	display: flex;
	flex-direction: column;

	&_name {
		@include typography.font-headline-5;
	}
	
	&_position {
		@include typography.font-body-2;
		
		color: var(--color-secondary-2);
	}
}
