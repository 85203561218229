.title {
  margin-bottom: 2rem;
}

.form_components {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.wrapper {
  display: flex;
  gap: 1.5rem;

  & .right {
    display: flex;
    flex: 1 1 80%;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}