@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;
}

.list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .75rem;
}

.card {
	padding: 1rem;
	border: 1px solid var(--color-secondary-1);
	border-radius: 8px;

	cursor: pointer;

	&:hover {
		box-shadow: var(--shadow-hover);
	}

	& > span {
		@include typography.font-body-1;

		color: var(--color-secondary-3)
	}
}

.title {
	display: flex;
	gap: .75rem;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 0.5rem;
}