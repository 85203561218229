.container {
  position: relative;
  top: 0;
  left: 0;
}

.avatar {
  position: relative;
  top: 0;
  left: 0;

  &:hover {
    cursor: pointer;
  }
}

.options {
  position: absolute;
  z-index: 1000;
  top: 3.125rem;
  right: 0;

  width: 12.5rem;
}
