$icon-size: .75rem;

.container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  color: var(--color-secondary-1);

  border: none;

  fill: var(--color-secondary-1);

  & > svg {
    width: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    min-height: $icon-size;
  }

  &:hover {
    cursor: pointer;
    color: var(--color-primary-1);
    fill: var(--color-primary-1);
  }

  &:active {
    color: var(--color-secondary-3);
    fill: var(--color-secondary-3);
  }
}
