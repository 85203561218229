@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.card {
	@include layout.card;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1rem;

	display: flex;
	align-items: center;
	gap: 1rem;

	& > div:first-child {
		flex-shrink: 0;
	}
}

.name {
	font-weight: 600;
}

.position {
	@include typography.font-body-2;
	color: var(--color-secondary-2);
}