@use "../../styles/shared.module";

.delete {
	position: absolute;
	top: .5rem;
	right: .5rem;
}

.thumb {
	cursor: pointer;
	position: relative;
	display: inline-flex;
	box-sizing: border-box;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: inherit;
	}
}

.avatar_default {
	@include shared.avatar-default-item;
}

.avatar_circle {
	@include shared.avatar-circle-item;
}