@use 'src/shared/styles/variables/typography';
@use '../../_shared/shared.module';

.container {
	@include shared.gallery-item;
	@include typography.font-body-1;

	flex-direction: column;
	gap: 1.3125rem;

	color: var(--color-primary-1);

	background-color: var(--color-bg-blocks);
	border: 1px solid var(--color-primary-1);
	border-radius: .625rem;
	box-shadow: var(--shadow-default);

	& .main_text > svg {
		fill: var(--color-primary-1);
	}

	&:hover {
		cursor: pointer;
		color: var(--color-primary-2);
		border: 1px solid var(--color-primary-2);
		box-shadow:var(--shadow-hover);

		& .main_text >  svg {
			fill: var(--color-primary-2);
		}
	}

	&:active {
		color: var(--color-primary-3);
		border: 1px solid var(--color-primary-3);

		& .main_text > svg {
			fill: var(--color-primary-3);
		}
	}

	&:focus-visible {
		border: 1px solid var(--border-focus);
		outline: none;
	}

	&[aria-disabled='true'] {
		color: var(--color-black-3);
		border: 1px solid var(--color-black-3);
		box-shadow: var(--shadow-default);

		& .main_text > svg {
			fill: var(--color-black-3);
		}
	}
}

.size {
  &_full {
		@include shared.item-full;
  }

  &_small {
		@include shared.item-small;
		padding: 1rem;
  }
}

.main_text {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.error {
	@include typography.font-body-2;

	margin: 0 1rem;
	color: var(--error-dark);
	text-align: center
}
