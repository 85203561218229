.title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}
