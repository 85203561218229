@use 'src/shared/styles/variables/typography';

.body1 {
	@include typography.font-body-1;
}

.body2 {
	@include typography.font-body-2;
}

.link {
	cursor: pointer;
	color: var(--color-primary-1);
	text-decoration: none;
}