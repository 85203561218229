@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	grid-column: 1 / 3;
	grid-row: 2;

	& > div:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.lineTabs {
	margin-bottom: 1.5rem;
}

.users_hours {
	color: var(--color-secondary-3);
}
