@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	& > h5 {
		color: var(--color-secondary-2);
	}
}

.wrapper {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding: 2rem 1.5rem;
}

.content {
	display: flex;
	gap: 1.5rem;
	align-items: flex-start;
	justify-content: space-between;

	& h3 {
		color: var(--error-dark);
	}
}

.title {
	@include typography.font-text-placeholder;
}

.empty {
	@include typography.font-body-1;
}