@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.catalog {
	@include layout.card;
	display: grid;

	&_title {
		margin-bottom: 1.5rem;
	}

	&_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0.75rem;
		border-radius: 0.5rem;
		margin-bottom: 0.25rem;
	}

	&_item:hover {
		background-color: var(--color-bg-hover-50);
	}

	&_dropdown {
		display: none;
	}

	&_item:hover &_dropdown {
		display: block;
	}

	&_item:last-of-type {
		margin-bottom: 0;
	}

	& span {
		@include typography.font-body-1;
		color: var(--color-black-4);
	}
}

.button {
	margin-top: 1.5rem;
}
