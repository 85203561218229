@use "src/shared/styles/layout/layout";

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& > h3 {
		text-align: center;
	}
}

.colors {
	display: flex;
	flex-wrap: wrap;
	gap: 1.35rem;
}

.buttons {
	display: flex;
	gap: 1rem;
}
