@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 0.75rem;

}

.table {
	position: relative;
	display: flex;
	gap: .125rem;
	background-color: var(--color-bg-blocks);
}