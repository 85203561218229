.container {
  min-width: .75rem;
  min-height: .75rem;
  border-radius: 100%;

  &__size {
    &_xs {
      width: .75rem;
      height: .75rem;
    }

    &_s {
      width: 1.125rem;
      height: 1.125rem;
    }

    &_m {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__color {
    &_0 {
      background-color: var(--color-1);
    }

    &_1 {
      background-color: var(--color-2);
    }

    &_2 {
      background-color: var(--color-3);
    }

    &_3 {
      background-color: var(--color-4);
    }

    &_4 {
      background-color: var(--color-5);
    }

    &_5 {
      background-color: var(--color-6);
    }

    &_6 {
      background-color: var(--color-7);
    }

    &_7 {
      background-color: var(--color-8);
    }

    &_8 {
      background-color: var(--color-9);
    }

    &_9 {
      background-color: var(--color-10);
    }

    &_10 {
      background-color: var(--color-11);
    }

    &_11 {
      background-color: var(--color-12);
    }

    &_none {
      background-color: var(--color-black-3);
    }
  }
}
