@use 'src/shared/styles/variables/typography';

.container {
	display: grid;
	grid-template: repeat(2,1fr) / minmax(0,1fr) minmax(0,1fr);
	gap: 1rem;
	margin-bottom: 2.375rem;

	& > div:first-child {
		grid-column: 1 / 3;
	}
}

.info_field {
	display: flex;
	align-items: center;
}

.icon {
	display: flex;
	align-items: center;
	width: 24px;
	margin-right: .5rem;


	& > svg {
		fill: var(--color-secondary-2);
	}
}

.field {
	@include typography.font-body-1;

	width: 9.75rem;
	margin-right: 1rem;
	color: var(--color-secondary-3);
}

.info {
	@include typography.font-body-1;
}