.title {
  margin-bottom: 2rem;
}

.main_block {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

@media screen and (width <= 1024px) {
  .main_block {
    flex-direction: column;
  }
}

.second_block {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}
