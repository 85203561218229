@use 'src/shared/styles/variables/mediaQuery';

.container {
	display: flex;
	flex-direction: column;
	gap: .75rem;
}

.filters_wrapper {

	@include mediaQuery.on-break-point(mobileDevices) {
		display: none;
	}

	display: flex;
	gap: 1.5rem;

	& > div:first-child {
		flex: 0 1 10%;
		min-width: 167px;
	}

	& > div:nth-child(2) {
		flex: 0 1 15%;
		min-width: 167px;
	}

	& > div:last-child {
		flex: 0 1 20%;
	}
}

// mobile

.mobile_container {
	position: fixed;
	z-index: 199;
	top: 70px;
	left: 0;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 100%;
	height: 100%;
	padding: 16px;

	background: #F4F8FC;
}

.mobile_header {
	display: flex;
	align-items: center;
}

.filter {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.subtitle {
	margin-bottom: 0.75rem;
	font-weight: 600;
}

.option {
	display: flex;
	gap: 0.25rem;
	align-items: center;
}

.mobile_filters_wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
