.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 0.75rem;

  border-radius: .5rem;

  & > button {
    display: none;
  }

  &:hover {
    background-color: var(--color-bg-hover-50);
    cursor: pointer;

    & > .buttons {
      display: flex;
    }
  }
}

.clicked {
  background-color: var(--color-bg-1);
  color: var(--color-black-1);

  &:hover {
    background-color: var(--color-primary-1);
  }

  &_buttons > * > svg {
    fill: var( --color-secondary-1);
  }
}

.name {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.buttons {
  display: none;
  gap: .75rem;
}
