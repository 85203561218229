.container {
  width: 0.75rem;
  height: 1.25rem;
  outline: none;
  fill: var(--color-secondary-2);

  &:hover {
    cursor: move; // fallback if grab cursor is unsupported
    cursor: grab;
  }

  &_horizontal {
    transform: rotate(90deg);
  }
}
