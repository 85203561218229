@use 'src/shared/styles/variables/typography';

// * Mixins
@mixin disabled {
	cursor: default;
	color: var(--color-black-3);
	background: var(--color-bg-blocks);
	box-shadow: var(--shadow-default);
}

// * Styles
.container {
	@include typography.font-headline-5;

	width: fit-content;
	padding: .75rem 1.5rem;

	background: var(--color-bg-blocks);
	border-radius: 12px;
	box-shadow: var(--shadow-default);

	&:hover {
		cursor: pointer;
		color: var(--color-black-1);
		background: var(--color-bg-1);
	}

	&:active, &.active {
		color: var(--color-black-1);
		background: var(--color-primary-1);
	}

	&:focus-visible {
		outline: 2px solid var(--border-focus);
	}
}

.disabled {
	@include disabled;

	&:hover {
		@include disabled;
	}

	&:active {
		@include disabled;
	}
}

.archived {
	color: var(--color-black-3);
}