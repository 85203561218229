.wrapper {
	display: grid;
	gap: 1rem;
}

.filters {
	display: flex;
	justify-content: space-between;
	gap: 1.5rem;
	margin-bottom: 1.5rem;
}
