.search_bar {
	position: relative;
}

.search_list {
	// position: absolute;
	width: 100%;
}

.options {
	position: absolute;
	z-index: calc(var(--z-index-input) + 1);
	width: inherit;
	outline: none;
}

.search_results {
	color: var(--color-secondary-3);
}