@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: fit-content;
}

//* Questions
.questions {
  @include layout.card;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 1.5rem;

  & hr {
    height: 0;
    border-color: var(--color-secondary-1);
    border-style: solid;
    border-width: 1px 0 0;
  }
}

.question_image {
  display: flex;
  justify-content: center;

  & > img {
    max-height: 20rem;
  }
}

.drag_drop_icon {
  display: flex;
  justify-content: center;
}

.question_header {
  display: flex;
  gap: .75rem;
  align-items: center;

  & > div:first-child {
    flex: 1 0 65%;
  }
}

// * Options
.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.option_field {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.add_option {
  display: flex;
  gap: 1.5rem;
  padding: 0 4rem;
}

// * footer section
.footer {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-end
}

.switch_obligation {
  @include typography.font-body-1;

  display: flex;
  gap: .75rem;
  align-items: center;
  color: var(--color-secondary-3);

	&_disabled {
		color: var(--color-black-3);
	}
}
