@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

// * Sections
.section {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.section_count {
	@include typography.font-text-label;

	width: fit-content;
	height: 1.625rem;
	padding: 1.5px 1.5rem;

	color: var( --color-bg-blocks);

	background: var(--color-primary-1);
	border-radius: 8px 8px 0 0;
}

.section_top {
	height: .5rem;
	background: var(--color-primary-1);
	border-radius: 0 8px 0 0;
}

.section_title {
	@include layout.card;

	display: flex;
	gap: .5rem;
	align-items: center;

	padding: 1rem 1.5rem;

	border-radius: 0 0 8px 8px;
}