@use 'src/shared/styles/variables/typography';

.title {
	margin-bottom: 2rem;
}

.filters {
	margin-bottom: 2rem;
}

.count_text {
	margin-bottom: 1rem;
}

.header {
	display: flex;
	margin-bottom: .5rem;
	margin-left: 5rem;

	& > span {
		@include typography.font-body-3;

		flex: 1 1 26rem;
		color: var(--color-secondary-2);
		text-transform: uppercase;
	}
}

.loading {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}

.position {
	@include typography.font-headline-5;

	display: flex;
	flex-direction: column;
	font-weight: 600;

	& > span {
		@include typography.font-body-2;

		color: #6C7898;
	}
}

.department {
	@include typography.font-body-4;
}

.users_list {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

