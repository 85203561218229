@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	justify-content: space-between;
	align-items: center;

	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
	}
}

.title {
	@include typography.font-headline-8;

	margin-bottom: .25rem;
}

.count {
	@include typography.font-body-2;

	color: var(--color-secondary-3);
}