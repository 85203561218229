.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;

	width: 30rem;

	text-align: center;
}

.name {
	color: var(--color-primary-1);
}