.buttonRow {
  display: flex;
  justify-content: center;

  & button:not(:last-child) {
    margin-right: 16px;
  }
}

.form {
  width: 792px;
  margin: 0 auto;
}
