@use "../../styles/shared.module";

.container {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;

	max-width: 80.25rem;
}

.thumbs {
	display: inline-flex;
	flex-wrap: wrap;
}

.avatar_default {
	@include shared.avatar-default-item;
}

.avatar_circle {
	@include shared.avatar-circle-item;
}
