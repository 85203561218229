@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.common_settings {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 100%;
	min-width: 25.75rem;
	max-width: 53rem;
}

.survey_header {
	@include typography.font-headline-3;
}

.survey_about {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.survey_options {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	margin-bottom: 1rem;

	& > div {
		flex: 1 1 40%;
	}
}

.additional {
	display: flex;
	gap: 1.5rem;
}
