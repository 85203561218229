.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header {
  margin-bottom: 1rem;
}

.list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  align-items: stretch;

  //display: flex;
  //flex-wrap: wrap;
  //gap: 1rem;
  //
  //& > div {
  //  flex: 1 1 30%;
  //}
}
