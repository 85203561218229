@use 'src/shared/styles/variables/typography';

.container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	row-gap: .5rem;

	& > div:nth-child(odd) > span.field {
		min-width: 77px;
	}

	& > div:nth-child(even) > span.field {
		min-width: 140px;
	}
}

.wrapper {
	@include typography.font-body-1;

	display: flex;
	align-items: center;
	gap: 2rem;

	& > span:first-child {
		color: var(--color-secondary-3);
	}
}

.value {
	display: flex;
	align-items: center;
	gap: .75rem;
}

.clickable {
	color: var(--color-primary-1);
	cursor: pointer;
}

p {
	@include typography.font-body-1;
}