@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	max-width: 50%;

	& > h3 {
		@include typography.font-headline-7;
	}

	@media screen and (width <= 1024px) {
		max-width: 100%;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
}

.map {
	position: relative;
	padding: 0;

	&__image {
		width: 100%;
		height: 18rem;
		object-fit: cover
	}

	&__placeholder {
		@include typography.font-headline-3;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 100%;

		color: var(--color-black-1);
		text-align: center;
		white-space: pre-wrap;
	}
}

.officeAbout {
	display: flex;
	flex-direction: column;
	gap: .5rem;

	&__inner {
		display: flex;
		align-items: start;
		justify-content: space-between;
	}
}

.description {
	@include typography.font-body-4;
}

