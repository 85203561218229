@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	width: 100%;
	padding: .5rem;
}

.wrapper {
	display: flex;
	gap: 1rem;
	align-items: center;

	& > div:first-child {
		flex: 0 0 auto;
	}
}

.label {
	@include typography.font-body-1;
}