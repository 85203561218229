@use 'src/shared/styles/variables/typography';

@mixin grid-base {
	display: grid;
	gap: 1.5rem;
}

.container {
	min-width: 800px;
}

.wrapper {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	max-height: calc(90svh - 11.25rem);

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	margin-bottom: 1.5rem;
	padding: 10px;
}

.fields {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	&_checkbox {
		@include typography.font-text-placeholder;

		display: flex;
		gap: 0.75rem;
		align-items: center;
		padding: 0.5rem 0;
	}
}

.probation {
	position: relative;
	top: 30px;
}

.personal_info {
	@include grid-base;

	grid-template-columns: 1fr 1fr;

	&_inner {
		display: flex;
		gap: 1.5rem;
	}
}

.work_info {
	@include grid-base;

	grid-template-columns: 1fr 1fr 1.2fr;

	&_last_row {
		@include grid-base;

		grid-template-columns: 105px 105px 180px;
	}
}

.addition_info {
	display: flex;
	gap: 1.5rem;
	align-items: flex-start;
}

.dates {
	display: flex;
	flex: 1 0 auto;
	justify-content: space-between;

	& > div {
		width: 9rem;
	}
}
