@use 'src/shared/styles/variables/typography';

.header {
	display: flex;
	gap: 1rem;

	margin-right: 9rem;
	margin-bottom: .5rem;
	margin-left: 9rem;

	& > span {
		@include typography.font-body-3;

		flex: 1 1 26rem;
		color: var(--color-secondary-3);
		text-transform: uppercase;
	}
}

.users_list {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.removeButton {
	cursor: pointer;
	fill: var(--color-secondary-2);

	&:hover {
		fill: var(--color-secondary-3);
	}

	&:active {
		fill: var(--color-secondary-3);
	}
}
