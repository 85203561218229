@use '../mixins.module';

.container {
	display: grid;
	grid-template-columns: 1fr;
	width: 100vw;
	
	&__visible_menu {
		max-height: 100svh;
		overflow: hidden;
	}
}

@keyframes menu_open {
  0% { transform: translateX(-100%) }
  100% { transform: translateX(0) }
}

.menu {
  @include mixins.menu;

	position: fixed;
	width: 272px;
	
	transform: translateX(-100%);

	&_open {
		transform: translateX(0);
		animation: menu_open 0.3s normal;
	}
}

.content {
  @include mixins.content;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;

	position: fixed;
  z-index: 230;
  inset: 0;

  width: 100%;
  height: 100%;

  background: rgb(0, 0, 0, 0.5);
}