.edit_wrapper {
  & > button {
    margin-bottom: 1.5rem;
  }
}

.columns {
  display: flex;
  gap: 1.5rem;
}

.wrapper {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
};