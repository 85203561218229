@use "src/shared/styles/layout/layout";

.chip_container {
  // ! DUPLICATE
  @include layout.flex(row, .5rem, flex-start);

  flex-wrap: wrap;
  width: 100%;
  margin-top: .5rem;
}
