.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
}

.inner {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}