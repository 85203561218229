@use "src/shared/styles/variables/typography";

.label {
	margin-bottom: 1rem;
	color: var(--color-secondary-3);
}

.hours {
	@include typography.font-headline-3;

	width: 5rem;

	&_postfix {
		color: var(--color-secondary-3)
	}
}
