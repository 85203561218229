.title {
	margin-bottom: 2rem;
}

.form_select {
	display: grid;
	grid-template-columns: 1fr 3fr;
	column-gap: 1.5rem;
	margin: 0.75rem 0 1.5rem 0;
	align-content: end;
	height: 100%;

	&_bottom {
		margin-top: auto;
	}

	&_inline {
		display: grid;
		grid-template-columns: 3fr auto;
		align-items: center;
		justify-content: center;
		column-gap: 1.5rem;
	}
}

.button_add {
	margin-bottom: 2rem;
}
