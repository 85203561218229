@mixin container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@mixin header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

@mixin button_wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
