@use "src/shared/styles/variables/typography";
@use "src/shared/styles/variables/mediaQuery";

.container {
	position: sticky;
	z-index: var(--z-index-header);
	top: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	padding: 1.25rem 2rem;

	background-color: var(--color-bg-default);
}

.left {
	display: flex;
	align-items: center;
}

.title {
	@include typography.headline-base;

	@include mediaQuery.on-break-point(desktopLargeOnly) {
		display: none;
	}

	margin-right: 2rem;
	font-size: 1.125rem;
	// line-height: 1.35rem; // Кажись только портить.
	text-decoration: none;
}



