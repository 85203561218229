@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.flex(row, 0, flex-start);

	flex-basis: 37%;
	margin-bottom: 13px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 25px;
	min-width: 25px;
	max-width: 25px;
	margin-right: .5rem;

	& > svg {
		width: 25px;
		min-width: 25px;
		max-width: 25px;
		fill: var(--color-secondary-1);
	}
}

.text {
	@include typography.font-body-3;

	width: 125px;
	color: var(--color-secondary-1);
}

.info {
	@include typography.font-body-3;
}
