.modal_content {
  width: 24.75rem;
}

.modal_title {
  margin-bottom: 2rem;
  text-align: center;
}

.modal_description {
  margin-bottom: 2rem;
  text-align: center;
}

.remove_name {
  color: var(--color-primary-5);
}

.modal_buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}