@use 'src/shared/styles/variables/mediaQuery';

.container {

	@include mediaQuery.on-break-point(desktopLargeOnly) {
		grid-template-columns: 1fr 3fr;
	}

	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 1.5rem;
}