.container {
	padding: 0;
	border-radius: 0;
	box-shadow: none;

	&__full_height {
		height: calc(100% - 58px);
	}

	& > div:last-child {
		height: 100%;
		align-content: center;
	}

	& > div[class*="datePicker"] {
		margin-bottom: .375rem;
	}

	& > div[class*="chart"] {
		margin-bottom: .375rem;

		& > div[class*="wrapper"] {
			width: 13.75rem;
			height: 13.75rem;
		}
	}

	& > span {
		height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	}
}

.button {
	margin: auto;
	margin-top: 20px;
}