@use "src/shared/styles/variables/typography";

.empty {
	@include typography.font-text-placeholder;

	color: var(--color-secondary-3);
}

.list {
	& > div:not(:last-child) {
		margin-bottom: .75rem;
	}
}