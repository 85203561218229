@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.wrapper {
	@include layout.card;
}

.title {
	margin-bottom: 1rem;
}

.data {
	@include typography.font-body-2;
	color: var(--color-secondary-3);
}

.creation {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
}
