.dottedList {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

.dot {
  width: 0.7vw;
  height: 0.7vw;
  margin-right: 1.8vw;

  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.text {
  width: 100%;
  font-size: 1.5vw;
  line-height: 2vw;
  color: #6B6B6B;
}

@media screen and (width >= 1920px) {
  .dottedList {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .dot {
    width: 15px;
    height: 15px;
    margin-right: 35px;

    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .text {
    width: 100%;
    font-size: 30px;
    line-height: 40px;
    color: #6B6B6B;
  }
}