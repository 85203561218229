@use '../menu_mixins.module';

.container {
  @include menu_mixins.container;

  padding-left: 1.5rem;
}

.container_inner {
  @include menu_mixins.container;

  padding-left: 3rem;
}

.active {
  @include menu_mixins.active;
}

.name {
	width: calc(100% - 24px);
	overflow-wrap: break-word;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px;
  fill: var(--color-black-4);
}