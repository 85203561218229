@use 'src/shared/styles/layout/layout';

.container {
	@include layout.flex(column, 1.5rem, center);

	& > div {
		max-width: 60rem;
		width: 100%;
	}
}

.wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;

	& > div {
		width: 468px;
	}
}