$cell-size: 1.5rem;
$cell-gap: .125rem;
$month-gap: .5rem;

.container {
	position: absolute;
	z-index: 4;

	width: $cell-size;
	height: $cell-size;

	background-color: purple;
	border-radius: .1rem;
}