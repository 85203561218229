.form {
	overflow: auto;

	min-width: 45rem;
	max-height: calc(90svh - 11.25rem);
	
	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	padding: 10px;

	& > div {
		margin-bottom: 2rem;
	}
}

.row {
	display: flex;
	gap: 2rem;
	align-items: flex-end;
}

.input_range {
	display: flex;
	gap: .25rem;
	align-items: flex-end;
	width: 100%;
}

.separator {
	display: flex;
	align-items: center;

	width: fit-content;
	height: calc(2.5rem + 2px);

	color: var(--color-secondary-3)
}

.switch {
	padding: .5rem 0;
}