.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fields {
	width: 100%;
	margin-bottom: 2rem;

	& > div:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	&__row {
		display: flex;
		gap: 1rem;
		align-items: start;
	}
}

.buttons {
	display: flex;
	gap: 1.5rem;
	justify-content: center;
}
