.container {
	display: flex;
	align-items: center;
	justify-content: center;

	width: fit-content;
	height: fit-content;

	border: none;
	border-radius: 100%;

	& > svg {
		fill: var(--color-secondary-2);
	}

	&:hover {
		cursor: pointer;

		& > svg {
			fill: var(--color-secondary-3);
		}
	}

	&_disabled {
		cursor: default;

		& > svg {
			fill: var(--color-black-3);
		}

		&:hover {
			cursor: default;
	
			& > svg {
				fill: var(--color-black-3);
			}
		}
	}
}
