.title {
	margin-bottom: 1.5rem;
	text-align: center;
}

.inner {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	max-height: calc(90svh - 12rem);
	margin-bottom: 2rem;
}

.dates {
	display: flex;
	gap: 1rem;
}

.row {
	display: flex;
	gap: 1.5rem;
	justify-content: center;
}
