.container {
  width: 100%;
}

.label {
  display: inline-block;

  height: fit-content;
  margin-bottom: .5rem;
  margin-left: .25rem;

  color: var(--color-secondary-3);
}

.inputs_wrapper {
  display: flex;
  gap: .5rem;
  align-items: flex-end;
}

.separator {
  display: flex;
  align-items: center;

  width: fit-content;
  height: calc(2.5rem + 2px);

  color: var(--color-primary-10);

}
