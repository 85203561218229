.container {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  align-items: start;
}

.chip {
  padding: .25rem .75rem;
  overflow-wrap: break-word;
  background-color: var(--color-bg-active-bar);
  border-radius: .5rem;
}
