.container {
	position: relative;
	width: 100%;
}

.calendar {
	& > button {
		margin: -1rem auto 1.5rem;
	}
}

.text_input {
	& * {
		cursor: pointer;
	}
}
