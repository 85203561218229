@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

$icon-size: 1rem;

.table {
	border-spacing: 0 .5rem;
	border-collapse: separate;
	width: 100%;

	thead {
		th {
			@include typography.font-body-2;

			padding-left: .5rem;
			color: var(--color-secondary-3);
			text-align: start;

			&:first-child {
				padding-left: 1.5rem; 
			}

			&:last-child { 
				padding-right: 1.5rem; 
			}
		}
	}

	tbody {
		tr {
			@include layout.card;

			td {
				padding: 1rem .5rem;

				&:first-child {
					padding-left: 1.5rem; 
					border-top-left-radius: 10px; 
					border-bottom-left-radius: 10px;
				}

				&:last-child { 
					padding-right: 1.5rem; 
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}
			}
		}
	}

	&_clickable {
		tbody {
			tr {
				&:hover {
					cursor: pointer;
					box-shadow: var(--shadow-hover)
				}
			}
		}
	}
}

.header_wrapper {
	margin: 0 1.5rem;
}

.cell_card {
	@include layout.card;
}

.sort_wrapper {
	display: flex;
	align-items: center;
	height: 1.5rem;
	
	& svg {
		fill: var(--color-secondary-2);
	}
}