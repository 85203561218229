@use "src/shared/styles/variables/typography";

.container {
	margin-bottom: -0.75rem;
}

.table {
	border-spacing: 0 .75rem;

	tbody {
		tr {
			background-color: var(--color-bg-hover-50);

			td {
				&:nth-child(2) {
					width: 40%;
				}

				&:first-child {
					padding-left: 2rem;
				}

				&:last-child {
					padding-right: 2rem;
				}
			}
		}
	}
}

.name {
	font-weight: 600;
}

.position {
	@include typography.font-body-2;
	color: var( --color-secondary-3);
}

.removeButton {
	cursor: pointer;
	fill: var(--color-secondary-2);

	&:hover {
		fill: var(--color-secondary-3);
	}

	&:active {
		fill: var(--color-secondary-3);
	}
}
