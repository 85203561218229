$right-padding: 1rem;

.table {
	border-collapse: collapse;
	width: 100%;

	thead {
		th {
			padding-right: $right-padding;

			font-size: 0.875rem;
			font-weight: normal;
			color: var(--color-secondary-3);
			text-align: start;
			white-space: nowrap;
		}
	}

	tbody {
		td {
			padding: 1rem $right-padding 1rem 0;
			border-top: 1px solid var(--color-bg-hover-75);

			&.grayed {
				padding-top: 0;
				color: var(--color-black-3);
				border-top: none
			}
		}
	}
}
