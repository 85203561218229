@use "src/shared/styles/variables/typography";

.title {
  @include typography.font-headline-7;
  margin-bottom: 1.5rem;
}

.gallery {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &_row {
    margin-top: 1.5rem;
  }
}

.delete {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: none;

  & > svg {
    fill: var(--color-black-2);
  }
}

.image_container {
  display: inline-flex;
  position: relative;

  &:hover {
    img {
      filter: brightness(0.7);
      cursor: pointer;
    } 

    .delete {
      display: block;
    }
  }
}

.image {
  width: 150px;
  height: 120px;
  object-fit: cover;
  border-radius: .25rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.file {
  @include typography.font-body-2;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  .formatIcon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: .75rem;
  }

  .name {
    flex-basis: 66%;
  }

  .size {
    flex-basis: 33%;
    color: var(--color-secondary-3);
  }

  & svg {
    fill: var(--color-secondary-2)
  }
}