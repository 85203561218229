@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";
@use 'src/shared/styles/variables/mediaQuery';

.container {
  @include layout.card;

  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: space-between;

  min-width: 25.75rem;
	min-height: 11.5rem;

	&:hover {
    box-shadow: var(--shadow-hover);
  }
}

.survey_dot {
  position: absolute;
  top: 8px;
  left: 8px;

  width: .5rem;
  height: .5rem;

  border-radius: 50%;

  &_new {
    background: var(--error-dark);
  }

  &_complete {
    background: var(--success-dark);
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  @include typography.font-text-label;

  overflow: hidden;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // количество строк
}

.actions {
  display: flex;
  align-items: center;
}

.done_icon {
  & svg {
    fill: var(--success-dark);
  }
}

.description {
  @include typography.font-body-2;

	@include mediaQuery.on-break-point(mobileDevices) {
		overflow: hidden;
		display: -webkit-box;

		white-space: pre-wrap;

		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
  }

	overflow: hidden;
	text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom {

  @include mediaQuery.on-break-point(mobileOnly) {
    flex-direction: column;
    row-gap: 0.75rem;
    align-items: flex-start;
  }

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.period {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.date {
	@include typography.font-body-2;

  & > span:first-child {
    color: var(--color-secondary-3);
  }

  &_disabled {
    color: var(--color-black-3);
  }
}

.category {

  @include typography.font-body-3;

  display: flex;
  align-items: center;

  height: 1.75rem;
  padding: 0 0.75rem;

  color: white;

  background: var(--new-diagrams-11, #7377DD);
  border-radius: 0.5rem;
}
