@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	gap: .25rem;
	align-items: center;

	padding: .5rem 0;

	background-color: transparent;
	border: none;

	&:hover {
		cursor: pointer;
	}

	& .icon {
		margin: 6px 8px;
		fill: var(--color-secondary-2);
	}

	& .text {
		@include typography.font-body-1;

		color: var(--color-secondary-2);
	}
}
