@use "src/shared/styles/variables/typography";

.container {

	@include typography.font-body-3;

	width: fit-content;
	padding: .375rem .75rem;
	color: var(--color-black-1);
	border-radius: .5rem;
}

.background {
	&_0 {
		background-color: var(--color-1)
	}

	&_1 {
		background-color: var(--color-2)
	}

	&_2 {
		background-color: var(--color-3)
	}

	&_3 {
		background-color: var(--color-4)
	}

	&_4 {
		background-color: var(--color-5)
	}

	&_5 {
		background-color: var(--color-6)
	}

	&_6 {
		background-color: var(--color-7)
	}

	&_7 {
		background-color: var(--color-8)
	}

	&_8 {
		background-color: var(--color-9)
	}

	&_9 {
		background-color: var(--color-10)
	}

	&_10 {
		background-color: var(--color-11)
	}

	&_11 {
		background-color: var(--color-12)
	}
}
