@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.title {
	@include typography.font-headline-3;

	margin-bottom: 1.5rem;
	text-align: center;
}

.inner {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	margin-bottom: 1.5rem;
}

.inner_success {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__name {
		color: var(--color-primary-1)
	}
}

.buttons {
	display: flex;
	gap: 1.5rem;
	justify-content: space-between;

	& > button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
}
