@use 'src/shared/styles/variables/typography';

// * Styles
@mixin container {
  @include typography.font-body-1;

  display: flex;
  row-gap: .25rem;
  align-items: flex-start;

  width: 100%;

  text-align: start;

  & .icon {
    padding: 8px 6px;
    fill: var(--color-secondary-2);
  }

  &:hover {
    cursor: pointer;
    color: var(--color-bg-1);

    & .icon {
      fill: var(--color-bg-1);
    }
  }

  &:active {
    color: var(--color-primary-1);

    & .icon {
      fill: var(--color-primary-1);
    }
  }

  &:focus-visible {
    border-radius: .5rem;
    outline: 2px solid var(--border-focus);
  }
}

@mixin active {
  color: var(--color-primary-1);

  & .icon {
    fill: var(--color-primary-1);
  }
}