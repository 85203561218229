.container {
	min-width: 24.75rem;

	display: flex;
	flex-direction: column;
	gap: 2rem;

	& > h2 {
		text-align: center;
	}
}

.description {
  text-align: center;
	white-space: pre-wrap;
}

.buttons {
	display: flex;
	justify-content: center;
	gap: 2rem;
}