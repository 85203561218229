@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;

	position: sticky;
	top: var(--header-height);
	height: fit-content;
	padding: 1rem .5rem 1.5rem 1rem;

	.go_back {
		margin-bottom: .5rem;
	}
}

.tree_wrapper {
	overflow-y: auto;
	max-height: calc(100svh - 242px);

	&::-webkit-scrollbar:vertical{
		background-color: var(--color-bg-hover-25);
	}
	
	&::-webkit-scrollbar-thumb {
		background: var(--color-bg-hover-75);
	}

	& > div:not(:last-of-type) {
		margin-bottom: .5rem;
	}
}

.menu_collapse {
	@include typography.font-body-1;

	display: flex;
	column-gap: .25rem;
	align-items: flex-start;

	width: 100%;

	text-align: left;

	& > svg {
		padding: 8px 6px;
		fill: var(--color-secondary-2);
	}

	&:hover {
		cursor: pointer;
		color: var(--color-bg-1);

		& .icon {
			fill: var(--color-bg-1);
		}
	}

	&:active {
		color: var(--color-primary-1);

		& .icon {
			fill: var(--color-primary-1);
		}
	}

	&:focus-visible {
		border-radius: .5rem;
		outline: 2px solid var(--border-focus);
	}
}

.name {
	overflow-x: hidden;
	width: 100%;
	text-align: start;
	text-overflow: ellipsis;
}

.heading_wrapper {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}
