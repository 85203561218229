@use 'src/shared/styles/variables/typography';
@use './local_common.module';

.container {
	@include local_common.container;

	color: var(--color-black-1);
	background: var(--color-primary-1);
	border: 1px solid var(--color-primary-1);
	border-radius: 1rem;

	& svg {
		fill: var(--color-black-1);
	}

	&:hover {
		background: var(--color-primary-2);
		border: 1px solid var(--color-primary-2);
	}

	&:focus-visible {
		border: 1px solid var(--border-focus);
		outline: none;
	}

	&:active {
		background: var(--color-primary-3);
		border: 1px solid var(--color-primary-3);
	}
}

.disabled {
	color: var(--color-black-3);
	background: var(--color-black-2);
	border: 1px solid var(--color-black-2);

	&:hover {
		cursor: default;
		color: var(--color-black-3);
		background: var(--color-black-2);
		border: 1px solid var(--color-black-2);
	}

	&:active {
		cursor: default;
		color: var(--color-black-3);
		background: var(--color-black-2);
		border: 1px solid var(--color-black-2);
	}

	& svg {
		fill: var(--color-black-3);
	}
}
