@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.edit_wrapper {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
}

.edit_actions {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.article {
	display: flex;
	flex-direction: column;
	row-gap: .75rem;

	&_name {
		@include typography.font-body-1;
	}
}

.empty_list {
	@include typography.font-body-1;

	color: var(--color-secondary-3);
}

.archived {
	color: var(--color-black-3);
}

.hover {
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}
