.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  min-width: 24.75rem;
}

.text {
  text-align: center;

  &_highlighted {
    color: var(--color-secondary-2);
  }
}
