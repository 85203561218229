@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	gap: 1.5rem;
}

.rows, .columns {
	display: flex;
	flex: 1 1 49%;
	flex-direction: column;
	gap: 1rem;
}

.row_field, .column_field {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.rows_button {
	padding: 0 2.5rem;
}

.columns_button {
	padding: 0 2.5rem 0 3.3rem;
}

.count {
	@include typography.font-body-2;
}
