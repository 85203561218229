@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;

	position: sticky;
	top: 6rem;
	display: flex;
	align-items: center;
}

.count {
	@include typography.font-headline-8;

	margin-right: 2rem;
}

.types {
	display: flex;
	gap: .75rem;
}

.remove {
	display: flex;
	gap: .75rem;
	align-items: center;
	margin-left: auto;
}