.container {
  width: 100%;
  min-width: 22rem;
}

.fields {
  display: flex;
  gap: .5rem;
  align-items: flex-end;

  & > div {
    flex: 1 0 10rem;
  }
}

.separator {
  display: flex;
  align-items: center;

  width: fit-content;
  height: calc(2.5rem + 2px);

  color: var(--color-secondary-3)
}
