@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card-interactive;

	cursor: pointer;

	position: relative;

	width: 100%;
	height: 8rem;
	padding: 0;

	border-radius: .25rem;
}

.overlay {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
	
	border-radius: .25rem;

	transition: linear .25s;

	& > svg {
		fill: none;
	}

	&:hover {
		background-color: var(--color-bg-modals);

		& > svg {
			fill:var(--color-black-1);
		}

		& .imageName {
			color: var(--color-black-1);
		}
	}
}

.imageName {
	position: absolute;
	bottom: 0;

	overflow-x: hidden;

	width: inherit;
	padding: .5rem;

	color: transparent;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.image {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	object-fit: cover;
	border-radius: .25rem;
}