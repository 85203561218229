@use 'src/shared/styles/variables/typography';

.event {
  @include typography.font-body-3;

  overflow: hidden;

  padding: 3px 6px 3px 12px;

  color: #fff;
  text-overflow: ellipsis;

  border-radius: .5rem;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }
}