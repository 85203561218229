@use 'src/shared/styles/variables/typography';

.container {
	@include typography.font-body-2;

	position: relative;

	display: flex;
	flex-direction: column;
	gap: .25rem;

	padding: 1rem 1.5rem;

	border-radius: .5rem;
	border: 1px solid var(--color-secondary-1);
}

.date_time {
	@include typography.font-headline-5;

	display: flex;
	gap: 2rem;
	align-items: center;

	&__time {
		color: var(--color-primary-1)
	}
}

.delete_icon {
	position: absolute;
	top: 1rem;
	right: 1rem;

	width: 1.25rem;
	height: 1.25rem;

	& > svg {
		width: 1.25rem;
		height: 1.25rem;
		fill: var(--color-secondary-1);
	}

	&:hover > svg {
		cursor: pointer;
		fill: var(--color-primary-1);
	}

	&:active > svg {
		fill: var(--color-primary-1)
	}
}

.extra_info {
	display: flex;
	flex-direction: column;
}

.caption {
	color: var(--color-black-3)
}

.readonly {
	&:hover {
		cursor: default;
		box-shadow: none;
	}

	&:active {
		box-shadow: none;
	}
}
