@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

$padding-gap: layout.$card-padding;

.container {
	@include layout.card;

	display: flex;
	align-items: center;
	padding: 1.5rem 2rem;
}

.inner {
	display: flex;
	flex: 1;
	align-items: center;

	& > div {
		flex: 1;

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.left {
	display: flex;
	align-items: center;
	justify-content: start;
	padding-right: 1rem;
}

.right {
	display: flex;
	align-items: center;
	justify-content: end;
	padding-left: 1rem;
}

.clickable {
	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
	}

	&:active {
		box-shadow: var(--shadow-active);
	}
}
