$close-icon-size: 1rem;

%button {
	position: fixed;
	z-index: 2000;
}

.close_button {
	cursor: pointer;

	position: absolute;
	top: $close-icon-size;
	right: $close-icon-size;

	width: $close-icon-size;
	height: $close-icon-size;

	& svg {
		width: $close-icon-size;
		height: $close-icon-size;
		fill: var(--color-secondary-1);
	}

	&:hover {
		& > svg {
			fill: var(--color-primary-1);
		}
	}
}

.modal_image {
	position: fixed;
	z-index: 2000;
	inset: 0;

	overflow-y: hidden;

	max-width: 80vw;
	max-height: 80vh;
	margin: auto;

	object-fit: contain;

	&:hover {
		cursor: pointer;
	}
}

.close {
	@extend %button;

	top: 1.5rem;
	right: 1.5rem;
}

.next {
	@extend %button;

	top: 49vh;
	right: 1.5rem;
}

.previous {
	@extend %button;

	top: 49vh;
	left: 1.5rem;
}

.download {
	@extend %button;

	top: 1.5rem;
	right: 6rem;
}
