@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.history {
	display: flex;
	gap: 0.5rem;
	margin-bottom: 1rem;
}

.history:last-child {
	margin-bottom: 0rem;
}

.date {
	@include typography.font-body-2;
	color: var(--color-secondary-3);
}
