@use "src/shared/styles/variables/typography";

.container {
	position: relative;
	width: 100%;
}

.search_list {
	position: absolute;
	width: 100%;
}