.container {
  position: fixed;
  z-index: 1000;
  right: 2rem;
  bottom: 2rem;

  width: 25rem;

  & > div:not(:last-child) {
    margin-bottom: .625rem;
  }
}
