@use 'src/shared/styles/variables/typography';

.tabsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: .75rem;
}

.add_rubric {
	min-height: 46px;
	border-radius: 12px;
	
	&:has(svg) {
		padding: .5rem 1rem;
	}
}
