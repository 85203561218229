.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
}

.header {
	text-align: center;
	white-space: pre-line;
}