.inner {
  margin-bottom: 2rem;
}

.image {
  position: relative;
  width: 100%;
  height: 25rem;
  background-color: black;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.delete {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.button {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
