.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;

	& > div {
		display: flex;
		gap: 0.75rem;
		align-items: center;
	}
}