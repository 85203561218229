@use 'src/shared/styles/variables/mediaQuery';

$content-Padding: 2rem;

@mixin container {
	// column-gap - is the gap between left menu and content.
  // NOTE: There is menu on tablet an lower.
  // * Mobile and tablet
  @include mediaQuery.on-break-point(mobileDevices) {
		grid-template-columns: 1fr;
		column-gap: unset;
	}

	// * Desktop
  // - Small monitors
  @include mediaQuery.on-break-point(desktopSmallOnly) {
		grid-template-columns: 88px 1fr;
	}

	// - Medium monitors
  @include mediaQuery.on-break-point(desktopMediumOnly) {
		grid-template-columns: 92px 1fr;
	}

	// - Large monitors
  @include mediaQuery.on-break-point(desktopLargeOnly) {
		grid-template-columns: 248px 1fr;
		width: calc(1980px - 2 * $content-Padding);
		margin: auto;
	}

	display: grid;
	grid-template-columns: 84px 1fr;
	width: 100vw;
}

@mixin menu {

	@include mediaQuery.on-break-point(mobileDevices) {
		display: none;
	}

	position: sticky;
	z-index: var(--z-index-nav);
	top: 0;
	left: 0;
}

@mixin content {
	padding: calc($content-Padding / 2) $content-Padding $content-Padding $content-Padding;
}
