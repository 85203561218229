.title {
  margin-bottom: 32px;
  text-align: center;
}

.image {
  margin: 0 auto 32px;
}

.buttonRow {
  display: flex;
  justify-content: center;

  & button:not(:last-child) {
    margin-right: 12px;
  }
}