@use "src/shared/styles/variables/typography";

.form {
	& > h4 {
		@include typography.font-headline-8;

		&:first-of-type {
			margin-bottom: 0.75rem;
		}

		&:last-of-type {
			margin-bottom: 1rem;
		}
	}
}

.fields {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	align-items: center;

	margin-bottom: 2rem;
}

.addButton {
	margin-bottom: 2rem;
}