@use 'src/shared/styles/variables/typography';

.container {
  width: 100%;

  display: inline-block;
  padding: 1.5rem;

  background-color: #FDFEFF;
  border-radius: .5rem;
  box-shadow: var(--shadow-default);
}

header {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1.5rem;
}

.headerDisplay p {
  @include typography.font-headline-3;

  width: 195px;
  color: var(--color-primary-1);
  text-align: center;
}

.arrow {
  display: flex;
  cursor: pointer;

  margin: 0;
  padding: .5rem;

  fill: var(--color-secondary-2);

  &:hover {
    fill: var(--color-primary-1);
  }
}

.days,
.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: space-between;
  gap: .5rem;
  margin: auto;
}

.week div {
  @include typography.font-body-1;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 123px;

  color: var(--color-secondary-3);
}

.day_wrapper {
  position: relative;
  overflow: hidden; 

  height: 123px;
  min-width: 123px;  
  border-radius: .125rem;
  background: var(--color-bg-hover-50);

  & > * {
    min-height: 123px;
  }
  
  &:hover {
    z-index: 10;
    overflow: visible;

    .day {
      box-shadow: var(--shadow-hover);
      background: #ecf5fe;
    }

    .selectedDate {
      background: var(--color-primary-1);
      color: var(--color-black-1);
    }
  
    .currentDate {
      background: var(--color-bg-1);
      color: var(--color-black-1);
    }
  }
}

.eventAndBirthday {
  overflow-y: auto;
  max-height: 13rem;

  text-overflow: ellipsis;
  white-space: nowrap;

  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.day {
  @include typography.font-headline-4;
  cursor: pointer;

  position: relative;
  padding: 1rem .5rem .5rem;
}

.dayNumber {
  @include typography.font-headline-4;
  margin-bottom: .5rem;
  margin-left: .5rem;
  color: inherit;
}

.weekend {
  color: var(--color-primary-1);
}

.selectedDate {
  background: var(--color-primary-1);
  color: var(--color-black-1);
}

.currentDate {
  background: var(--color-bg-1);
  color: var(--color-black-1);
}