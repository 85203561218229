$card-padding: 1.5rem;
$close-icon-size: 24px;

.container {
  pointer-events: none;

  position: fixed;
  z-index: -1;
  inset: 0; // ? На весь экран, нужно уточнить как работает.

  opacity: 0;

	&_collapsed {
		inset: auto 1rem 1rem auto;
		width: fit-content;
		height: fit-content;

		& .overlay {
			border-radius: 1rem;
		}

		& .content {
			overflow: hidden;
			width: 20rem;
			height: 3rem;
		}
	}
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: var(--color-bg-modals);
}

.content {
  position: relative;
  transform: scale(.5);

	max-height: 90svh;
  padding:  1rem;
	padding-top: .75rem;

  background-color: var(--color-bg-blocks);
  border-radius: 1rem;
  box-shadow: var(--shadow-modals);

  transition: .3s transform;

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		&__icons {
			display: flex;
			gap: .5rem;
			align-items: center;
			margin-left: auto;
		}
	}

	&__inner {
    padding: .5rem .75rem;
  }

	&_xl {
		width: 56.75rem;
	}

	&_l {
		width: 50rem;
	}

	&_m {
		width: 39.5rem;
	}

	&_s {
		width: 29.75rem;
	}

	&_xs {
		width: 22.5rem;
	}
}

.opened {
  pointer-events: auto;
  z-index: var(--z-index-modal);
  opacity: 1;

  & .content {
    transform: scale(1);
  }
}

.isClosing {
  & .content {
    transform: scale(.2);
  }
}

.collapsed {
	// &__title {
	
	// }

	&__button {
		cursor: pointer;
	  // cursor: pointer;
		// position: absolute;
		// top: 1rem;
		// right: calc(1rem * 2 + .75rem);

		& svg {
			fill: var(--color-secondary-1);
		}

		&:hover {
			& > svg {
				fill: var(--color-primary-1);
			}
		}
	}
}

.close {
  cursor: pointer;
  // position: absolute;
  // top: 1rem;
  // right: 1rem;

  & svg {
    fill: var(--color-secondary-1);
  }

  &:hover {
    & > svg {
      fill: var(--color-primary-1);
    }
  }
}
