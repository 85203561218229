$cell-size: 1.5rem;
$cell-gap: .125rem;
$month-gap: .75rem;

.container {
	font-size: .75rem;
	background-color: var(--color-bg-blocks);
}

.calendar {
	display: flex;
	gap: $month-gap;
}

.column {
	& > div:not(:last-child) {
		margin-bottom: $cell-gap;
	}
}

.header {
	position: sticky;
	z-index: 5;
	top: 0;

	margin-right: -1rem;
	padding-right: 1rem;

	background-color: var(--color-bg-blocks);
}

.row {
	position: relative;
	display: flex;
	gap: $cell-gap;
	height: $cell-size;
	
	&__month {
		justify-content: center;
		color: var(--color-secondary-3);
	}
}

// TODO: Удалить уже есть в Cell
.cell {
	position: relative;
	z-index: 1;

	width: $cell-size;
	height: $cell-size;

	border-radius: .1rem;

	&__day {
		color: var(--color-secondary-3);
		text-align: center
	}

	&__selectable {
		cursor: pointer;
		background-color: var(--color-bg-hover-50);

		&:hover {
			z-index: 3;
			background-color: var(--color-2);
		}
	
		// &:active {
		// 	background-color: var(--color-2);
		// }

		&_selected {
			z-index: 3;
			background-color: var(--color-2);
		}

		&_holiday {
			// z-index: 3;
			background-color: #F0A89E;
		}
	}
}

.selection {
	position: absolute;
	z-index: 2;

	width: $cell-size;
	height: $cell-size;

	background-color: transparent;
}