@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.files {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.table {
	@include typography.font-body-2;

	border-spacing: 8px 0;
	width: 100%;

	& tr {
		width: 100%;
		height: 1.75rem;
	}

	& th {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
		text-align: left;
		text-transform: uppercase;
	}

	& td {
		text-align: left;
	}

	&_size {
		width: 5rem;
		color: var(--color-secondary-3);
	}

	&_td {
		width: 1.5rem;
	}

	&_icon {
		width: 1.5rem;

		display: flex;
		align-items: center;
		justify-content: center;

		& > svg {
			fill: var(--color-secondary-2)
		}
	}

	&_action {
		&:hover {
			cursor: pointer;
		}
	}
}

.modal {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;

	&_title {
		@include typography.font-headline-3;
	}

	&_text {
		text-align: center;

		&_highlighted {
			color: var(--color-secondary-2);
		}
	}

	&_file_name {
		margin-bottom: 1.5rem;
		padding: 1rem;

		color: var(--color-secondary-3);

		background-color: var(--background-input);
		border-radius: 12px;
	}

	&_warning {
		@include typography.font-text-description;

		color: var(--color-primary-1);
	}

	&_buttons {
		display: flex;
		flex: 1 0;
		gap: 12px;
	}
}
