@use "src/shared/styles/variables/typography";

.row {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
}

.empty_container {
	& > p {
		@include typography.font-text-placeholder;
	
		color: var(--color-secondary-3);
	}
}