@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
}

.title {
	@include typography.font-headline-3;

	&_disabled {
		color: var(--color-black-3);
	}
}

.survey_info {
	@include typography.font-body-2;

	margin-top: .25rem;
	color: var(--color-secondary-3);
}
