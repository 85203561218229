@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/layout/layout';

.container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.header {
	@include layout.card;

	display: flex;
	gap: 1rem;
	align-items: center;
}

.buttons_wrapper {
	display: flex;
	gap: .75rem;

	& > button {
		white-space: nowrap;
	}
}

.edit_info {
	@include typography.font-body-3;

	color: var(--color-secondary-3);

	&_name {
		color: var(--color-secondary-3)
	}
}


