.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.marginWrapper {
  margin-bottom: 24px;
}

.row {
  display: flex;
  gap: 2rem;
  margin-bottom: 24px;

  & > div {
    flex: 1 1 10%;
  }
}
