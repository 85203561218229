$arrow-size: 1.5rem;

.arrow {
	width: $arrow-size;
	height: $arrow-size;

	&__primary {
		fill: var(--color-primary-1);
	}

	&__secondary {
		fill: var(--color-secondary-1)
	}
}
