@use "src/shared/styles/layout/layout";

$icon-width: .875rem;
$icon-padding: 1.0625rem;
$border-radius: .75rem;

.container {
	padding: .5rem;
	background: var(--color-bg-blocks);
	border-radius: $border-radius;
	box-shadow: var(--shadow-default);
}

.list {
	background: var(--color-bg-blocks);
	border-radius: $border-radius;

	&_item {
		display: flex;
		flex: 1;
		align-items: center;
		border-radius: $border-radius;

		& svg {
			flex-grow: 0;
			flex-shrink: 0;

			width: fit-content;
			margin-right: -0.25rem;
			padding-left: .75rem;
		}

		&:hover {
			cursor: pointer;
			background-color: var(--color-bg-hover-25);
		}

		&:active {
			color: var(--color-primary-1);
			background-color: var(--color-bg-active-bar);
		}

		&:focus-visible {
			background-color: var(--color-bg-blocks);
			outline: none;
		}
	}

	&_item_text {
		overflow: hidden;

		padding: .5rem .75rem;

		text-overflow: ellipsis;
		overflow-wrap: anywhere;
		white-space: nowrap;
	}
}

.selected_item {
	color: var(--color-primary-1);
	background-color: var(--color-bg-active-bar);
}
