.title {
	margin-bottom: 24px;
}

.row {
	display: flex;
	align-items: end;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
}

.fields {
	margin-bottom: 24px;
}

.container {
	& button {
		margin-bottom: 24px;
	}
}


