.container {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	min-width: 800px;
	max-height: 80vh;

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	padding: 10px;
}

.wrapper {
	display: grid;
	grid-template-columns: 1fr 162px 162px;
	grid-template-rows: repeat(2, auto);
	gap: 1.5rem;
}

.wrapper_inner {
	grid-column: 1 / 4;

	& > div {
		margin-bottom: 0.375rem;
	}
}

.form_buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
}