@mixin container {
  display: flex;
  align-items: center;

  width: 100%;

  text-align: left;

  border-radius: .5rem;
}

@mixin default {
  background: var(--color-bg-blocks);
  border: 2px solid var(--color-bg-blocks);
}

@mixin name {
  overflow-x: hidden;
  text-align: start;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}
