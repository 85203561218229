@use "src/shared/styles/variables/typography";

$container-margin: .25rem; // Margin is required to proper shadow display.

$border-radius: .75rem;
$input-height: 1.4375rem;
$padding-vertical: 0.5313rem;
$padding-horizontal: 1rem;
$icon-width: 3.75rem;
$component-height: calc($input-height + $padding-vertical * 2 + 2px); // + 1px to compensate for 1px border.

$text-left-position: .9375rem;

@mixin border {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  width: inherit;
  height: $component-height;
  padding: $padding-vertical $padding-horizontal;
}

@mixin border-hover {
  background: var(--color-bg-hover-50);
}

@mixin icon {
  position: absolute;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  width: calc($input-height - 4px);
  height: calc($input-height - 4px);

  fill: var(--color-secondary-2);

  &:hover ~ .border {
    @include border-hover;
  }
}

.container {
  width: 100%;
}

.label {
  display: inline-block;
  height: fit-content;
  margin-bottom: .5rem;
  color: var(--color-secondary-3);
}

.input_wrapper {
  position: relative;
  top: 0;
  left: 0;

  width: 100%;
  height: $component-height;

  &:hover > .border {
    @include border-hover;
  }
}

.placeholder {
  @include typography.font-text-placeholder;

  position: absolute;
  z-index: 2;
  top: calc($padding-vertical + 1px); // + 1px to compensate for 1px border.
  left: $padding-horizontal;

  overflow-x: hidden;

  width: calc(100% - $icon-width); // Inline in textInput

  color: var(--color-secondary-3);
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: .25s;
}

.input {
  @include typography.font-text-placeholder;

  position: absolute;
  z-index: var(--z-index-input);
  top: $padding-vertical; // extra 1px looks better...
  left: $text-left-position;

  display: flex;
  align-items: center;

  width: calc(100% - $icon-width); // Inline in textInput
  height: $input-height;

  background-color: transparent;
  border: none;
  outline: none;

  &:hover ~ .border {
    @include border-hover;
  }

  &:focus ~ .border,
  &:active ~ .border {
    border: 1px solid var(--color-primary-1);
  }

  &:focus ~ .border_error,
  &:active ~ .border_error {
    border: 1px solid var(--error-dark);
  }

  &:focus ~ .placeholder,
  &:active ~ .placeholder {
    pointer-events: none;
    opacity: 0;
  }
}

.border {
  @include border;

  background-color: var(--color-bg-hover-25);
  border: 1px solid var(--color-secondary-1);
  border-radius: $border-radius;

  &_bottom {
    @include border;

    border-bottom: 1px solid var(--color-secondary-1);
  }

  &_error {
    border: 1px solid var(--error-dark);
  }
}

.icon_left {
  @include icon;

  top: calc($padding-vertical + 3px); 
  left: $padding-horizontal;

  &__clickable {
    &:hover {
      cursor: pointer;
    }
  }
}

.icon_right {
  @include icon;

  top: calc($padding-vertical + 3px); 
  right: $padding-horizontal;

  &__clickable {
    &:hover {
      cursor: pointer;
    }
  }
}

.error {
  @include typography.font-body-3;

  display: inline-block;
  color: red;
}

// * Drop down only
.drop_down_container {
  &:hover {
    cursor: pointer;
  }
}

.value {
  position: absolute;
  z-index: var(--z-index-input);
  top: calc($padding-vertical + 1px); // + 1px to compensate for 1px border.
  left: $text-left-position;

  overflow: hidden;
	display: flex;
	align-items: center;

  width: calc(100% - $icon-width);
  height: $input-height;

  text-align: start;
  text-overflow: ellipsis;

	& svg {
		min-width: 1.75rem;
		max-width: 1.75rem;
		height: $input-height;
		padding-right: .25rem;
	}
}

.text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.active {
  & > .border {
    border: 1px solid var(--color-primary-1);
  }

  & > .placeholder {
    pointer-events: none;
    opacity: 0;
  }
}

.isPicker {
  &:hover > * {
    cursor: pointer;
  }
}

.disabled {
  & .border {
    color: var(--color-black-3);
    background-color: var(--color-bg-disabled);
    border: 1px solid var(--color-black-3);
  }

  & .placeholder {
    color: var(--color-black-3);
  }

  &:hover > * {
    cursor: default;
  }

  &:hover .border {
    background-color: var(--color-bg-disabled);
    border: 1px solid var(--color-black-3);
  }

  &:focus .border,
  &:active .border {
    border: 1px solid var(--color-black-3);
  }

  &:focus .placeholder,
  &:active .placeholder {
    opacity: 100;
  }

  & svg {
    fill: var(--color-black-3);
  }
}

.error_message {
  margin-top: .25rem;
}
