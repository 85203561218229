@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

@mixin active {
	color: var(--color-black-1);
	background: var(--color-primary-1);
	border-radius: 0.5rem;

	//box-shadow: 0 0 1rem 1px rgba(67, 81, 150, 0.4);
}

.title {
	margin-bottom: 2rem;
}

.main_block {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
}

.navigation {
	@include layout.card;

	position: sticky;
	top: 115px;

	display: flex;
	flex: 1 1 20%;
	flex-direction: column;
	justify-content: flex-start;

	height: fit-content;

	&_back {

		@include typography.font-headline-5;

		display: flex;
		gap: 0.25rem;
		margin-bottom: 1rem;
		font-weight: 600;
	}
}

.company_list {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	max-height: calc(100svh - 307px);

	// Не дает обрезать тень при overflow: auto
	margin: -10px;
	padding: 10px;
}

.company_name {

	@include typography.font-body-1;

	padding: 0.5rem 0.75rem;
	text-align: start;
	border-radius: 0.5rem;

	&:hover {
		cursor: pointer;
		background: var(--color-bg-hover-50);
		border-radius: 0.5rem;

		//box-shadow: 0 0 .75rem 1px rgba(67, 81, 150, 0.25);
	}

	&:focus-visible {
		background: var(--color-bg-blocks);
		border-radius: 0.5rem;
		outline: none;

		//box-shadow: 0 0 .75rem 1px rgba(67, 81, 150, 0.25);
	}

	&:active {
		@include active;
	}
}

.active {
	@include active;

	&:hover {
		@include active;
	}

	&:focus-visible {
		@include active;
	}
}

.about_department {
	@include layout.card;

	flex: 1 1 70%;
}

.description {
	&_text {
		@include typography.font-body-1;

		margin-top: 1.5rem;
		margin-bottom: 2rem;
	}
}

.filters {
	margin-bottom: 1.5rem;
}
