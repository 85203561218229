@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.container {
	flex: 1 1 70%;
	height: fit-content;
}

.container_empty {
	flex: 1 1 70%;
}

.description {
	margin-bottom: 1.5rem;
}

.header {
	display: flex;
	justify-content: space-between;
}

.filters {
	margin-bottom: 1.5rem;
}

.add_users {
	margin-bottom: 1rem;
}