@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
  @include layout.card;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  &:hover {
    box-shadow: var(--shadow-hover);
  }

  &:active {
    box-shadow: var(--shadow-active);
  }

  & .icon {
    margin-right: 0.375rem;
    fill: var(--color-primary-1);
  }
}

.cursor {
  cursor: pointer;
}

.unit_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;

  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  @include typography.font-text-label;

  overflow: hidden;
  display: -webkit-box;

  height: 2.62rem;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // количество строк
}

.about {
  display: flex;
  flex-direction: column;
}

.position, .count {
  @include typography.font-body-2;

  color: #6C7898;
}

.fullname {
  @include typography.font-body-1;
}

.bottom_line {
  display: flex;
  justify-content: space-between;
}
