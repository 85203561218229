@use './_mixins.module';
@use 'src/shared/styles/variables/typography';

// * Styles
.container {
	@include typography.font-headline-5;
	@include mixins.container;

	padding: 1rem 1.375rem;
	background: var(--color-bg-blocks);
	box-shadow: var(--shadow-default);

	& > svg {
		width: 1rem;
		min-width: 1rem;
		margin-right: .75rem;
		fill: var(--color-secondary-2);
	}

	&:hover {
		cursor: pointer;
		color: var(--color-black-1);
		background: var(--color-bg-1);
		box-shadow: var(--shadow-hover);

		& > svg {
			fill: var(--color-black-1);
		}
	}

	&:active, &.active {
		color: var(--color-black-1);
		background: var(--color-primary-1);
		box-shadow: var(--shadow-active);

		& > svg {
			fill: var(--color-black-1);
		}
	}

	&:focus-visible {
		outline: 2px solid var(--border-focus);
	}
}