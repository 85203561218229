.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;

	p {
		width: 80%;
		text-align: center;
	}

	&__buttons {
		display: flex;
		gap: 1.5rem;
	}
}