.container {
	overflow-x: hidden;
	display: flex;
	align-items: center;
	width: 100%;
}

.months {
	display: flex;
	flex: 1;
	gap: .75rem;
	justify-content: space-between;

	font-size: .75rem;
	color: var(--color-secondary-3);

	& > span {
		cursor: pointer;
		user-select: none;
	}
}

.arrow {
	cursor: pointer;

	position: sticky;

	width: 1rem;
	min-width: 1rem;
	height: 1rem;
	min-height: 1rem;

	background-color: var(--color-bg-blocks);

	fill: var(--color-secondary-3);

	&__left {
		left: 0;
	}
	
	&__right {
		right: -0.5px;
	}

	&_disabled {
		cursor: default;
		fill: var(--color-black-3);
	}
}