@use 'src/shared/styles/layout/layout';

.route {
	margin-bottom: 1.25rem;
}

.container {
	@include layout.card;
}

.header {
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 1.5rem;

	&__left {
		display: flex;
		gap: 2rem;
		align-items: center;
	}

	&__heading {
		white-space: nowrap;
	}

	&__select {
		width: 11rem;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	&_withImages {
		margin-bottom: 1.5rem;
	}

	&__row {
		display: flex;
		gap: 5rem;
	}
}