.tab_container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5rem;
}

.tabs {
	margin-bottom: 1.5rem;
}

.add_button {
	min-height: 6rem;

	&_users {
		min-height: 5rem;
		margin-bottom: .75rem;
	}
}