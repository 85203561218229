@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.heading {
	text-align: center;
}

.requirements {
	margin-bottom: 1.25rem;
}

.available {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	margin-bottom: 1.25rem;

	&__label {
		@include typography.font-field-label;

		color: var(--color-secondary-3);
	}

	&__value {
		@include typography.font-text-placeholder;
	}
}

.fields {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 1.25rem;

	height: 40vh;
	margin-right: -1rem;
	margin-bottom: 1.25rem;
	padding-right: 1rem;
}

.interval {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: .625rem;
	}

	&__title {
		@include typography.font-headline-8;
	}

	&__fields {
		margin-bottom: .625rem;
	}

	&__description {
		@include typography.font-field-label;

		color: var(--color-secondary-2);
	}

	&__addButton {
		margin-bottom: 2rem;
	}
}