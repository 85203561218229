@use 'src/shared/styles/variables/typography';

.container {
  @include typography.font-body-3;

  overflow: hidden;

  max-width: 160px;
  width: fit-content;
  padding: 6px 12px;

  color: var(--color-black-1);
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 8px;
}
