@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
}

.rubric_list {
	margin-bottom: 1.5rem;
}

.edit_wrapper {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 1rem;
}

.edit_actions {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.rubric_title {
	@include typography.font-headline-7;

	display: flex;
	gap: 0.75rem;
	align-items: center;

	& > svg {
		fill: var(--color-secondary-2);
	}
}

.disabled {
	color: var(--color-black-3)
}

.articles {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.section {
	&_container {
		margin-bottom: 2rem;
	}

	&_heading {
		margin-bottom: 1rem;
	}

	&_articles {
		display: flex;
		flex-direction: column;
		gap: .75rem;
	}
}

.nothing_found {
	color: var(--color-secondary-3);
	text-align: center;
}

.empty_list {
	color: var(--color-secondary-3);
}

