.title {
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	& h1 {
		margin-right: 12px;
	}

	& svg {
		fill: #3F98F5;
	}
}

.container_body {
	margin-top: 1.5rem;
}
