@use 'src/shared/styles/variables/typography';

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	justify-content: center;

	width: 24.75rem;
	padding: 0 .5rem .5rem;
}

.title {
	@include typography.font-headline-3;
}

.text {
	text-align: center;
	white-space: pre-line;
}

.buttons {
	display: flex;
	gap: .75rem;
	width: 100%;
}