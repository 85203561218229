.camera {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & path {
    fill: #818FB4;
  }
}

.cameraAdd {
  cursor: pointer;

  position: absolute;
  z-index: 6;
  right: 10px;
  bottom: 10px;

  width: 36px;
  height: 36px;

  background: #3F98F5;
  border-radius: 50%;

  & path {
    fill: #fff;
  }
}

.label {
  position: absolute;
  z-index: 10;
  top: 0;

  display: block;

  width: 100%;
  height: 100%;
}

.image {
  position: relative;
  z-index: 5;
  width: 100%;
}

.avatar {
  position: relative;

  overflow: hidden;

  width: 164px;
  height: 164px;

  border: 1px solid #B7C5E1;

  &__wrapper {
    position: relative;
    width: 164px;
    height: 164px;
  }
}