@use "_shared/shared.module";

//%button {
//  position: fixed;
//}

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: 80.25rem;
}

.thumbs {
  display: inline-flex;
  flex-wrap: wrap;

  &_full {
    gap: 1rem 1.5rem;
  }

  &_small {
    gap: 1rem;
  }
}

.size {
  &_full {
    @include shared.item-full;
  }

  &_small {
    @include shared.item-small;
  }
}

.thumb {
  @include shared.gallery-item;

  cursor: pointer;
  position: relative;
  display: inline-flex;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}
