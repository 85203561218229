@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/mediaQuery";

.container {
	@include layout.card-interactive;

	cursor: pointer;

	width: 100%;
	height: 12.5rem;
	padding: 0;

	object-fit: contain;

	@container gallery (max-width: 65rem) {
		height: 9rem;
	}
}