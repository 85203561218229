@use 'src/shared/styles/layout/layout';

.title {
	margin-bottom: 2rem;
}

.inner {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.table {
	@include layout.card;

	&_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	&_buttons {
		display: flex;
		gap: 1rem;
		align-items: center;
	}
}

.names {
	& > h3 {
		margin-bottom: .25rem;
	}

	& > h5 {
		color: var(--color-secondary-3)
	}
}
