@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	margin-top: 2rem;
}

.header {
	font-weight: 700;
}