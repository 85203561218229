@use "src/shared/styles/variables/typography";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > span {
		@include typography.font-text-placeholder;

		font-weight: 600;
		color: var(--color-secondary-3);
	}
}