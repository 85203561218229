// * Options
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fields {
  display: flex;
  gap: .75rem;
  align-items: center;

  & > div:first-child {
    flex: 1 0 15%;
  }
}