@use 'src/shared/styles/variables/typography';
@use "../../styles/shared.module";

.container {
	@include typography.font-body-1;

	cursor: pointer;

	flex-direction: column;
	gap: 1.3125rem;

	color: var(--color-primary-5);

	background-color: var(--background-menu);

	&[aria-disabled='true'] {
		color: var(--color-grayscale-4);
		background-color: var(--color-grayscale-7);
	}
}

.avatar_default {
	@include shared.avatar-default-item;
}

.avatar_circle {
	@include shared.avatar-circle-item;
}

.main_text {
	display: flex;
	gap: 1rem;
	align-items: center;

	& > svg {
		transform: translateY(.1rem);
		fill: var(--color-primary-5);
	}
}

.error {
	@include typography.font-body-2;

	margin: 0 1rem;
	color: var(--error-dark);
	text-align: center
}
