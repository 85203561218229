.inner {
  display: flex;
  gap: 1rem;

  & > div {
    padding: .5rem;
  }

  [class="react-datepicker"] {
    box-shadow: none;
	  border: 1px solid var(--color-secondary-1);
  }
}

.bookingList {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-height: 60vh;
  width: 100%;
  padding: 0 1rem;

  & > div {
    width: 100%;
  }
}
