.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  & h3:last-of-type {
    margin-bottom: 2rem;
  }
}

.place_name {
  color: var(--color-primary-1);
}
