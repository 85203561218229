$screen-desktop-xl-min: 1920px; // 16 px

$screen-desktop-l-max: 1919px;
$screen-desktop-l-min: 1440px;
$screen-desktop-m-max: 1439px;
$screen-desktop-m-min: 1280px;
$screen-desktop-s-max: 1279px;
$screen-desktop-s-min: 1024px;
$screen-tablet-max: 1023px;
$screen-tablet-min: 640px;
$screen-mobile-max: 639px;

@mixin on-break-point($breakpoint) {
  // * Desktop
  @if $breakpoint == desktop {
    @media (min-width: $screen-desktop-s-min) {
      @content;
    }
  }

  @if $breakpoint == desktopLargeOnly {
    @media (min-width: $screen-desktop-xl-min) {
      @content;
    }
  }

  @if $breakpoint == desktopMediumOnly {
    @media (min-width: $screen-desktop-l-min) and (max-width: $screen-desktop-l-max) {
      @content;
    }
  }

  @if $breakpoint == desktopSmallOnly {
    @media (min-width: $screen-desktop-m-min) and (max-width: $screen-desktop-m-max) {
      @content;
    }
  }

  @if $breakpoint == bellowDesktopLarge {
    @media (width >= 0) and (max-width: $screen-desktop-l-max) {
      @content;
    }
  }

  // * Mobile devices
  @if $breakpoint == mobileDevices {
    @media (max-width: $screen-tablet-max) {
      @content;
    }
  }

  @if $breakpoint == tabletOnly {
    @media (min-width: $screen-tablet-min) and (max-width: $screen-tablet-max) {
      @content;
    }
  }

  @if $breakpoint == mobileOnly {
    @media (max-width: $screen-mobile-max) {
      @content;
    }
  }
}
