@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.name {
	color: var(--color-primary-1)
}
