@use 'src/shared/styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  min-width: 303px;
  height: fit-content;
  overflow: auto;
  padding: 1.5rem;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow-default);
}

.section {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  & h4 {
    @include typography.font-body-1;
  }
}