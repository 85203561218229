@use "src/shared/styles/layout/layout";
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	width: 100%;
	height: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: .5rem;

	& > h3 {
		@include typography.font-headline-7;
	}
}

.empty_text {
	@include typography.font-text-placeholder;

	margin-top: 1.5rem;
	color: var(--color-secondary-3);
}

.bookingInfo {
	&__dateTime {
		@include typography.font-headline-4;

		display: flex;
		gap: 1rem;

		margin-bottom: .5rem;
	}

	&__time {
		color: var(--color-primary-1)
	}
}

.officeInfo {
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	gap: .25rem;

	&__label {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
	}

	&__value {
		@include typography.font-body-2;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
