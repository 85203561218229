.container {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
}

.options {
	position: absolute;
	z-index: calc(var(--z-index-input) + 1);
	width: inherit;
	outline: none;
}
