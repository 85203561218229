@use 'src/shared/styles/variables/typography';


.container {
	& > div:not(:last-child) {
		margin-bottom: .5rem;
	}
}

.requirement {
	display: flex;
	gap: .25rem;
	align-items: start;

	& > p {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
		// text-align: start;
	}
}

.icon {
	position: relative;
	top: .2rem;

	width: .75rem;
	min-width: .75rem;
	height: .75rem;
	min-height: .75rem;

	fill: var(--error-dark);

	&_done {
		fill: var(--success-dark)
	}
}