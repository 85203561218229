@use 'src/shared/styles/variables/typography';

.container {
	@include typography.font-text-placeholder;

	display: flex;
	gap: .5rem;
	align-items: center;
}

.default_color {
	color: var(--color-secondary-3);
}

.date {
	width: 100%;
	max-width: 9rem;
}