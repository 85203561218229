.title {
	margin-bottom: 2rem;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.row {
	display: flex;
	gap: 2rem;

	& > div {
		flex: 1 1 10%;
	}
}

.buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
}
