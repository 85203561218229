.form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.wrapper {
	text-align: center;

	&_name {
		color: var(--color-primary-1);
	}
}

.list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.radio {
	& > svg {
		flex-shrink: 0;
	}
}