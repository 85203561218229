@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.table {
	display: flex;
	flex-direction: column;
	gap: .5rem;

	&_row {
		display: grid;
		grid-template-columns: repeat(5, 1fr) 52px;
		gap: 1rem;
		align-items: center;
	}

	&_cell {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&_header {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
	}
}

.header_wrapper {
	margin: 0 1.5rem;
}

.cell_card {
	@include layout.card;

	cursor: pointer;

	&:hover {
		box-shadow: var(--shadow-hover);
	}
}

.status_wrapper {
	margin: .1875rem;
}