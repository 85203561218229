.container {
  width: 100%;
  margin-bottom: 1.5rem;
}

.nav {
  margin-bottom: 1rem;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;

  & > h1 {
    margin-right: .75rem;
  }

  & > svg {
    fill: var(--color-primary-1)
  }
}

.buttons {
  display: flex;
  gap: 1rem;
}
