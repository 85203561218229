.lineTabs {
  margin-bottom: 1.5rem;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.filters {
	display: flex;
	gap: 1rem;

	& > div:first-child {
		flex-basis: 14rem;
	}
}

.list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}