@import "src/shared/styles/variables/typography";

.Avatar {
	&_container {
		position: relative;

		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		background-color: var(--color-bg-1);
		border-radius: 100%;
	}

	&_size {
		width: 10.25rem;
		height: 10.25rem;
	}

	&_name {
		font-size: 3.125rem;
		font-weight: 600;
		color: var(--color-bg-blocks);
	}
}

.update {
	cursor: pointer;

	position: absolute;
	bottom: -50%;
	left: 0;

	width: 100%;
	height: 3.75rem;
	padding-top: 1rem;

	background-color: var(--color-bg-modals);
	border-radius: 0 0 100% 100%;

	transition: bottom .1s ease-in;

	& svg {
		fill: var(--color-black-1);
	}
}

.show_update {
	bottom: 0;
}