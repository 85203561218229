.filters {
	display: flex;
	gap: 1.5rem;
	align-items: center;
	margin-bottom: 1.5rem;

	& > div:first-child {
		flex: 1;
	}
}

.tabs {
	margin-bottom: 1.5rem;
}