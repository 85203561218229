.container {
  border: 3px solid transparent;
  border-radius: 50%;
  animation: rotation .8s ease infinite;

  &_primary {
    border-top-color: var(--color-primary-1);
    border-bottom-color: var(--color-primary-1);
  }

  &_secondary {
    border-top-color: var(--color-secondary-2);
    border-bottom-color: var(--color-secondary-2);
  }

  &_light {
    border-top-color: var(--color-black-1);
    border-bottom-color: var(--color-black-1)
  }

  &_small {
    width: 1.5rem;
    height: 1.5rem;
  }

  &_large {
    width: 4rem;
    height: 4rem;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


