@use 'src/shared/styles/layout/layout';

.container {
	@include layout.flex(row, 1.5rem, flex-start);

	& > div:first-child {
		flex-shrink: 0;
	}
}

.reply_container {
	margin-left: 5.25rem;
}

.comment {
	@include layout.flex(row, .5rem, flex-start);

	width: 100%;

	&__actions {
		display: flex;
		align-items: center;
	}

	&__send {
		padding: .5rem;
	}
}

.image_list {
	margin-left: 5.25rem;
	margin-top: 1rem;

	grid-template-columns: repeat(7, 1fr);
}

.reply_image_list {
	margin-left: 10.5rem;
}