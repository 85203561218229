.button {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 1.5rem 2rem;

  color: var(--color-primary-1);

  background-color: var(--color-bg-blocks);
  border: 1px solid var(--color-primary-1);
  border-radius: .625rem;
  box-shadow: var(--shadow-default);

  & > svg {
    fill: var(--color-primary-1);
  }

  &:hover {
    color: var(--color-primary-2);
    border: 1px solid var(--color-primary-2);
    box-shadow:var(--shadow-hover);

    & > svg {
      fill: var(--color-primary-2);
    }
  }

  &:active {
    color: var(--color-primary-3);
    border: 1px solid var(--color-primary-3);

    & > svg {
      fill: var(--color-primary-3);
    }
  }

  &:focus-visible {
    border: 1px solid var(--border-focus);
    outline: none;
  }

  &[aria-disabled='true'] {
    color: var(--color-black-3);
    border: 1px solid var(--color-black-3);
    box-shadow: var(--shadow-default);

    & > svg {
      fill: var(--color-black-3);
    }
  }
}
