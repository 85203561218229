@use 'src/shared/styles/variables/typography';
@use 'src/shared/styles/layout/layout';

$icon-width: .5625rem;
$icon-padding: .6875rem;

.container {
	@include typography.font-body-1;

	overflow: hidden;
	display: flex;
	gap: .6875rem;
	align-items: center;

	width: fit-content;
	max-width: 20rem;
	padding: .5rem .75rem;

	text-overflow: ellipsis;
	white-space: nowrap;

	border-radius: .75rem;

	&__empty {
		width: .75rem;
		height: 2.25rem;
		padding: 0;
	}

	&__blue {
		background-color: var(--color-bg-active-bar);
	}

	&__green {
		color: #00AF1C;
		background-color: #D5FBCA;
	}

	&__red {
		color: #A90513;
		background-color: #E79393;
	}
}

.svg {
	display: flex;
	align-items: center;

	width: $icon-width + $icon-padding;
	min-width: $icon-padding + $icon-width;
	max-width: $icon-width + $icon-padding;

	& > svg {
		width: $icon-width;
		min-width: $icon-padding;
		max-width: $icon-width;
		fill: var(--color-primary-9);
	}

	&:hover {
		cursor: pointer;
	}
}
