@use "src/shared/styles/variables/mediaQuery";
@use "src/shared/styles/variables/typography";
@use "src/shared/styles/packages/react-datepicker";

// TODO: fix font sizes!
@font-face {
  font-family: Montserrat;
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url("./fonts/Lato-Regular.ttf") format('truetype')
}

@font-face {
  font-family: Lato;
  font-weight: 600;
  src: url("./fonts/Lato-Semibold.ttf") format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  src: url("./fonts/Lato-Bold.ttf") format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 800;
  src: url("./fonts/Lato-Heavy.ttf") format('truetype');
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  src: url('/fonts/gilroy-bold.ttf') format('ttf'),
  url('/fonts/gilroy-bold.woff') format('woff'),
  url('/fonts/gilroy-bold.woff2') format('woff2'),
  url('/fonts/gilroy-bold.eot') format('eot'),
  url('/fonts/gilroy-bold.svg') format('svg');
}

@font-face {
  font-family: Gilroy;;
  font-weight: 500;
  src: url('/fonts/gilroy-regular.ttf') format('ttf'),
  url('/fonts/gilroy-regular.woff') format('woff'),
  url('/fonts/gilroy-regular.woff2') format('woff2'),
  url('/fonts/gilroy-regular.eot') format('eot'),
  url('/fonts/gilroy-regular.svg') format('svg');
}

:root {
  --color-primary-1: #3F98F5; // Main
  --color-primary-2: #3681D0; // Hover
  --color-primary-3: #2C6BAC; // Pressed
  --color-secondary-1: #B7C5E1; // Stroke & icons
  --color-secondary-2: #818FB4; // Side menu
  --color-secondary-3: #6C7898; // Icon/text
  --color-black-1: #FAFAFA;
  --color-black-2: #E0E0E0;
  --color-black-3: #BDBDBD;
  --color-black-4: #212121;
  --color-bg-blocks: #FDFEFF;
  --color-bg-modals: rgb(33 33 33 / 74%); // TODO: Узнать у дизайна. А точно ли нужен нам альфа канал?
  --color-bg-default: #F4F8FC;
  --color-bg-menu: #EAEFFB;
  --color-bg-hover-75: rgb(228 234 245 / 75%);
  --color-bg-hover-50: rgb(228 234 245 / 50%);
  --color-bg-hover-25: rgb(228 234 245 / 25%);
  --color-bg-disabled: #F4F4F4;
  --color-bg-active-bar: rgb(63 152 245 / 10%);
  --color-bg-1: #8CC1F9;
  --color-1: #3F98F5;
  --color-2: #5FB85D;
  --color-3: #9CB94B;
  --color-4: #ECBD19;
  --color-5: #FF9800;
  --color-6: #E0503C;
  --color-7: #8D6E63;
  --color-8: #F06292;
  --color-9: #BA68C8;
  --color-10: #52AEB4;
  --color-11: #6C8BBF;
  --color-12: #7377DD;

  // * Shadows
  --shadow-default: 0px 0px 8px 0px rgb(121 133 164 / 10%), 0px 0px 2px 0px rgb(121 133 164 / 40%);
  --shadow-hover: 0px 0px 20px 0px rgb(121 133 164 / 15%), 0px 0px 4px 0px rgb(121 133 164 / 40%);
  --shadow-active: 0px 0px 15px 1px rgb(67 81 150 / 40%);
  --shadow-modals:  0px 0px 35px 3px rgb(70 79 126 / 50%);
  --shadow-dropdown: 0px 10px 20px 0px rgb(121 133 164 / 15%), 0px 0px 2px 0px rgb(121 133 164 / 40%);

	// * Status
  --border-focus: #E8B733;
  --error-dark: #E4342C;
  --error-light: #FDE9EC;
  --warning-dark: #FFBA00;
  --warning-light: #FCFCE7;
  --success-dark: #00AF1C;
  --success-light: #EEFEEA;

	// * z-index
  --z-index-input: 100;
  --z-index-header: 200;
  --z-index-nav: 300;
  --z-index-modal: 400;
  --z-index-floating: 500;
  --z-index-notification: 600;
  --z-index-tooltip: 700;

  // * layout
  --header-height: 6.1rem;
	--card-border-radius: .5rem;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: Lato, sans-serif;
  font-size: 16px;
  background-color: var(--color-bg-default);
}

body {
  @include typography.font-body-1;

  overflow-x: hidden;
  width: 100vw;}

::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
  border-radius: .75rem;
}

::-webkit-scrollbar:vertical{
  background-color: var(--color-bg-hover-75);
}

::-webkit-scrollbar-thumb {
  background: var(--color-secondary-1);
  border-radius: .75rem;
}

h1 {
  @include typography.font-headline-1;
}

h2 {
  @include typography.font-headline-2;
}

h3 {
  @include typography.font-headline-3;
}

h4 {
  @include typography.font-headline-4;
}

h5 {
  @include typography.font-headline-5;
}

h6 {
  @include typography.font-headline-6;
}

strong {
  font-weight: 800;
}

p {
  overflow-wrap: anywhere;
}

// ! Clear button styles
button {
  cursor: pointer;

  padding: 0;

  font: inherit;
  color: inherit;

  background: none;
  border: none;
  outline: inherit;
}
