@use 'src/shared/styles/variables/typography';

.wrapper {
	overflow-y: auto;
	max-height: 70vh;
	padding: 0 0.75rem;
	margin-bottom: 0.5rem;
}

.title {
	margin-bottom: 1rem;
	text-align: center;

	&_month {
		color: var(--color-primary-1);
	}
}

.day {
	@include typography.font-headline-8;

	margin-bottom: 0.5rem;
	text-align: center;
}

.message {
	text-align: center;
}