@use 'src/shared/styles/variables/mediaQuery';

.container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;

	@media (max-width: mediaQuery.$screen-desktop-m-max) {
		grid-template-columns: 1fr 1fr;
	}
}