.label {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: fit-content;
  height: fit-content;

  & > div {
    margin-left: .5rem;
  }

  & > input {
    position: relative;
    z-index: -0.1rem;
    width: 0;
    opacity: 0;
  }

  &:hover {
    cursor: pointer;

    & .rect {
      stroke: var(--color-primary-2);
    }

    & .circle {
      fill: var(--color-primary-2);
    }
  }

  & .rect {
    stroke: var(--color-primary-1);
  }

  & .circle {
    fill: var(--color-primary-1);
  }

  &_disabled {
    & .rect {
      stroke: var(--color-black-2);
    }

    & .circle {
      fill: var(--color-black-2);
    }

    &:hover {
      cursor: default;

      & .rect {
        stroke: var(--color-black-2);
      }

      & .circle {
        fill: var(--color-black-2);
      }
    }
  }

  &_another_disabled {
    &:hover {
      cursor: default;

      & .rect {
        stroke: var(--color-primary-1);
      }
    
      & .circle {
        fill: var(--color-primary-1);
      }
    }
  }
}

