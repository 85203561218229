.circle {
  user-select: none;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  font-size: 1rem;
  color: #fff;

  background: var(--color-secondary-1);
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 56px;

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: -52px;
      transform: translateY(-50%);

      width: 48px;
      height: 1px;

      background: var(--color-secondary-1);
    }
  }

  &__active {
    background: var(--color-primary-1);
  }
}

.container {
  display: flex;
}