@use 'src/shared/styles/variables/typography';

.form {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& > h2 {
		text-align: center;
	}

	&__fields {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&__error {
		@include typography.font-body-1;

		color: var(--error-dark);
		text-align: center;
	}
}