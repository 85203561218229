.container {
	position: relative;
	width: 3rem;
	height: 1.5rem;
}

.checkbox {
	width: 0;
	height: 0;
	visibility: hidden;
}

.label {
	cursor: pointer;

	position: absolute;
	top: 0;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 3rem;
	height: 1.5rem;

	background: grey;
	border-radius: 6.25rem;

	transition: background-color .2s;
}

.label .button {
	content: '';

	position: absolute;
	top: .25rem;
	left: .25rem;

	width: 1rem;
	height: 1rem;

	background: #fff;
	border-radius: 2.8125rem;
	box-shadow: 0 0 2px 0 rgb(10 10 10 / 29%);

	transition: 0.2s;
}

.checkbox:checked + .label .button {
	left: calc(100% - .25rem);
	transform: translateX(-100%);
}

.label:active .button {
	width: 1.75rem;
}

.on {
	background-color: var(--color-primary-1);

	&:hover {
		background-color: var(--color-primary-2);
	}
}

.off {
	background-color: var(--color-secondary-1);

	&:hover {
		background-color: var(--color-secondary-2);
	}
}

.disabled {
	background-color: var(--color-black-2);

	&:hover {
		cursor: default;
		background-color: var(--color-black-2);
	}
}
