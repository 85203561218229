@mixin avatar-item {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 8rem;
  height: 6.5rem;

  // border-radius: .625rem;
  box-shadow: 0 0 .4375rem 1px rgb(5 35 181 / 8%);
}

@mixin avatar-circle-item {
  @include avatar-item;

  width: 6.5rem;
  border-radius: 50%;
}

@mixin avatar-default-item {
  @include avatar-item;
}

