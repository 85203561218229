.container {
  display: flex;
  flex-direction: column;
}

.options {
  display: flex;
  gap: 1rem;
}

.chip {
  box-sizing: content-box;
  padding: .25rem .75rem;
  border: 1px solid var(--color-bg-hover-50);
  border-radius: .5rem;

  & > svg {
    fill: var(--color-secondary-2);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-bg-hover-50);
    border: 1px solid transparent;
  }

  &:active, &_selected {
    background-color: var(--color-bg-active-bar);
    border: 1px solid transparent;
  }
}
