@use 'src/shared/styles/variables/typography';

.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: .75rem;
  justify-content: center;
}

.legend {
  display: flex;
  gap: .5rem;
  align-items: center;

  height: 1.25rem;

  & > span {
    @include typography.font-body-2;
  }
}
