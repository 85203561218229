@use 'src/shared/styles/variables/mediaQuery';
@use 'src/shared/styles/variables/typography';

@mixin active {
	& .link {
		width: 100%;
		background-color: var(--color-primary-1);
		border: 2px solid var(--color-primary-1);
	}

	& .icon {
		fill: var(--color-black-1);
	}

	& .title {
		color: var(--color-black-1);
	}

	& .dot {
		border-color: var(--color-primary-1);
	}
}

.container {
	// * Large monitors
  @include mediaQuery.on-break-point(desktopLargeOnly) {
		width: 14rem;

		& .icon {
			margin-right: .625rem;
		}

		& .title {
			white-space: nowrap;
		}

		& .link {
			width: 14rem;
		}
	}

	position: relative;
	z-index: var(--z-index-nav);

	display: flex;
	align-items: center;

	width: 75%;
	height: 2.5rem;

	&:hover {
		& .link {
			cursor: pointer;

			width: fit-content;

			background: var(--color-bg-1);
			border: 2px solid var(--color-bg-1);
			border-radius: 1rem;
		}

		& .title {
			display: inline-block;
			color: var(--color-black-1);
			white-space: nowrap;
		}

		& .icon {
			fill: var(--color-black-1);
		}

		& .dot {
			border-color: var(--color-bg-1);
		}

		@media (min-width: mediaQuery.$screen-desktop-xl-min) {
			& .link {
				width: 14rem;
				border-radius: 1rem 0 0 1rem;
			}
		}

	}

	&:active {
		& .link {
			background-color: var(--color-primary-1);
			border-color: var(--color-primary-1)
		}
	}

	&:focus-visible {
		outline: 2px solid var(--border-focus);
	}

	// для гамбургер меню
	&_desktop {
		width: 15rem;

		& .icon {
			margin-right: .625rem;
		}

		& .title {
			white-space: nowrap;
		}

		& .link {
			width: 15rem;
		}

		&:hover {
			& .link {
				width: 15rem;
				border-radius: 1rem 0 0 1rem;
			}
		}
	}
}

.link {
	position: absolute;
	z-index: var(--z-index-nav);

	display: flex;
	gap: .5rem;
	align-items: center;

	height: 2.5rem;
	padding: 0 .775rem;

	color: var(--color-secondary-2);
	text-decoration: none;

	background-color: transparent;
	border: 2px solid transparent;
	border-radius: 1rem 0 0 1rem;

	& .icon {
		fill: var(--color-secondary-2);
	}
}

.icon {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 1rem;
	height: 1rem;

	& .dot {
		position: absolute;
		top: -0.3rem;
		right: -0.2rem;

		width: .5rem;
		height: .5rem;

		background-color: red;
		border: .1rem solid var(--background-menu);
		border-radius: 100%;
	}
}

.title {
	@include typography.font-body-1;

	display: block;
	color: var(--color-secondary-3);

	&_adaptive {
		@include mediaQuery.on-break-point(bellowDesktopLarge) {
			display: none;
		}
	}
}

.active {
	@include active;

	@include mediaQuery.on-break-point(desktopLargeOnly) {
		width: 14rem;

		& .icon {
			margin-right: .625rem;
		}

		& .title {
			white-space: nowrap;
		}

		& .link {
			width: 14rem;
		}
	}

	&_desktop {
		width: 15rem;

		& .icon {
			margin-right: .625rem;
		}

		& .title {
			white-space: nowrap;
		}

		& .link {
			width: 15rem;
		}
	}
}
