@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

.container {
	@include layout.card;

	display: flex;
	grid-column: 2;
	grid-row: 1;
	flex-direction: column;
	//gap: 1.25rem;
}

.datePicker {
	margin-bottom: 6rem;
}

.chart {
	display: flex;
	align-items: center;
	align-self: center;
	justify-content: space-between;

	width: 100%;
	margin-bottom: 4rem;

	&__wrapper {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 300px;
		height: 300px;
	}
}

.total {
	@include typography.font-headline-3;

	position: absolute;

	&__fullyFilled {
		color: var(--success-dark);
	}
}

.noFilled {
	@include typography.font-body-2;

	color: var(--color-secondary-3);
	text-align: center;
}
