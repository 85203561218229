@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.wrapper {
	@include layout.card;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background: var(--error-dark);
	// &_new {
	// 	background: var(--error-dark);
	// }

	// &_complete {
	// 	background: var(--success-dark);
	// }
}

.approvers {
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

.job_title {
	@include typography.font-body-2;
	color: var(--color-secondary-2);
}
