.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	margin-bottom: 1.25rem;
}

.filters_select {
	display: flex;
	gap: 1rem;
}