@use "src/shared/styles/variables/typography";
@use "src/shared/styles/variables/mediaQuery";

.container {

	@include mediaQuery.on-break-point(mobileOnly) {
		flex-direction: column;
		align-items: flex-start;
	}

	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
}

.caption {
	@include typography.font-body-1;
}

.scale {

	@include mediaQuery.on-break-point(mobileOnly) {
		flex-direction: column;
	}

	display: flex;
	flex-direction: row;
	gap: .5rem 2rem;
}

.scale_item {

	@include mediaQuery.on-break-point(mobileOnly) {
		flex-direction: row-reverse;
		padding: .5rem 0;
	}

	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: center;
	justify-content: center;
}

