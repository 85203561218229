@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

$padding-gap: layout.$card-padding;
$icon-size: 1rem;

@mixin element-wrapper {
	display: flex;
	flex: 0 0 calc(5rem - $padding-gap);
	align-items: center;
}

.container {
	@include layout.card;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.info_wrapper {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	width: 100%;

	& > div {
		flex: 1 1 26rem;
		overflow-wrap: anywhere;
	}
}

.about {
	display: flex;
	flex-direction: column;
}

.fullname_star {
	display: flex;
	gap: .5rem;
	align-items: center;

	& > svg {
		width: $icon-size;
		height: $icon-size;
		fill: var(--warning-dark);
	}
}

.role {
	@include typography.font-body-2;

	color: #6C7898;
}

.units_and_contacts {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: flex-start;

	& > div {
		flex: 1 1 15rem;
	}
}

.contacts {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	justify-content: center;

	height: 100%;
}

.element_wrapper {
	&__left {
		@include element-wrapper;

		gap: 2rem;
		justify-content: start;
	}

	&__right {
		@include element-wrapper;

		justify-content: end;
	}
}
