@use 'src/shared/styles/layout/layout';

.container {
	@include layout.card;
}

.full_height {
	height: fit-content;
}

.header {
	@include layout.flex(row, 1rem, space-between);

	margin-bottom: 1rem;
}